import { createContext, useReducer } from "react";
import { produce } from "immer";
import type { Url } from "./types";
import { type ConfigId } from "@/constants/ConfigData";

interface State {
  botData: Record<string, any>;
  openConfigModalId: ConfigId | "";
  crawlData: Map<string, Url>;
  extraData: Map<string, Url>;
  url: string;
  tab: number;
}

type Action =
  | { type: "set-botData"; botData: Record<string, any> }
  | { type: "set-openConfigModalId"; configId: ConfigId | "" }
  | { type: "tab-set"; tab: number }
  | { type: "url-set"; url: string }
  | { type: "crawl-init"; url?: string; crawlData: Map<string, Url> }
  | { type: "crawl-reset" }
  | { type: "crawl-add"; crawlData: Map<string, Url> }
  | { type: "crawl-toggle"; id: string }
  | { type: "crawl-set-range"; range: number; state: boolean }
  | { type: "crawl-unselect" }
  | { type: "crawl-delete"; id: string }
  | { type: "crawl-delete-all" }
  | { type: "extra-add"; extraData: Map<string, Url>; selected: boolean }
  | { type: "extra-select"; id: string }
  | { type: "extra-delete"; id: string }
  | { type: "extra-delete-all" };

type Context = [State, React.Dispatch<Action>];

const initialState: State = {
  botData: {},
  openConfigModalId: "",
  url: "",
  tab: window.screen.width <= 1279 ? 2 : 1,
  crawlData: new Map(),
  extraData: new Map(),
};

const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case "set-botData": {
      draft.botData = action.botData;
      break;
    }
    case "set-openConfigModalId": {
      draft.openConfigModalId = action.configId;
      break;
    }
    case "tab-set": {
      draft.tab = action.tab;
      break;
    }
    case "url-set": {
      draft.url = action.url;
      break;
    }
    case "crawl-init": {
      if (action.url) draft.url = action.url;
      draft.crawlData = action.crawlData;
      break;
    }
    case "crawl-reset": {
      draft.url = "";
      draft.crawlData = new Map();
      draft.extraData = new Map();
      break;
    }
    case "crawl-add": {
      action.crawlData.forEach((value, key) => {
        draft.crawlData.set(key, value);
      });
      break;
    }
    case "crawl-toggle": {
      const value = draft.crawlData.get(action.id);
      if (!value) break;
      draft.crawlData.set(action.id, { ...value, selected: !value.selected });
      break;
    }
    case "crawl-set-range": {
      let counter = 0;
      draft.crawlData.forEach((value, key) => {
        if (action.range === -1 || counter < action.range) {
          draft.crawlData.set(key, { ...value, selected: action.state });
        } else {
          draft.crawlData.set(key, { ...value, selected: !action.state });
        }
        counter++;
      });
      break;
    }
    case "crawl-unselect": {
      draft.crawlData.forEach((value, key) => {
        draft.crawlData.set(key, { ...value, selected: false });
      });
      break;
    }
    case "crawl-delete": {
      draft.crawlData.delete(action.id);
      break;
    }
    case "crawl-delete-all": {
      draft.crawlData = new Map();
      break;
    }
    case "extra-add": {
      action.extraData.forEach((value, key) => {
        draft.extraData.set(key, {
          ...value,
          selected: action.selected,
        });
      });
      break;
    }
    case "extra-select": {
      const value = draft.extraData.get(action.id);
      if (!value) break;
      draft.extraData.set(action.id, { ...value, selected: true });
      break;
    }
    case "extra-delete": {
      draft.extraData.delete(action.id);
      break;
    }
    case "extra-delete-all": {
      draft.extraData = new Map();
      break;
    }
  }
});

export const BotContext = createContext<Context>([initialState, () => {}]);

export default function BotContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [store, storeDispatch] = useReducer(reducer, initialState);

  return (
    <BotContext.Provider value={[store, storeDispatch]}>
      {children}
    </BotContext.Provider>
  );
}
