import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosGet, axiosPost } from "../../services/axiosService";
import { userSlice } from "../../redux/User";
import "../../styles/main.css";

function Template({premade, showAll}) {
  const route = useNavigate();
  const dispatch = useDispatch();
  const [width, setWidth] = useState<any>();
  const [premades, setPremades] = useState<any>(premade ? premade :[])

 useEffect(()=>{
  if(showAll){
    setPremades(premade);
  }else{
    let tempPremades = [...premade];
    tempPremades.splice(5);
    setPremades(tempPremades);
  }

 }, [showAll, premade]);

 
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  };

  useEffect(() => {
    handleWindowSizeChange();
    console.log(premade)
  }, []);

  const [botUrl, setBotUrl] = useState<any>(false);
  const handleTemplateBot = (botURL:string) => {
    setBotUrl(botURL);
    axiosGet(`/users/templateBot?botURL=${botURL}`)
      .then((response: any) => {
        setBotUrl("");
        const { projectName, botCustomId, url } = response.data.data;

        if (width <= 900) route(`/preview/${url}`);
        else route(`/builder/${url}`);

        axiosPost("/audience/lists", {
          name: projectName,
          list_id: botCustomId,
          type: "bot",
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          userSlice.actions.setInfoModal({
            showModal: true,
            modalType: "error",
            modalTitle: "Error",
            modalMessage: err.response.data.message,
          })
        );
        setBotUrl("");
      });
  };

  const mouseOverHover = (e) => {
    if (e.target.closest(".template-header").querySelector("h2")?.scrollWidth > e.target.closest(".template-header")?.offsetWidth) {
      e.target.closest(".template-header")?.classList?.add("template-hover");
    }
  };
  const mouseLeaveHover = (e) => {
    e.target.closest(".template-header")?.classList?.remove("template-hover");
  };

  return (
    <Fragment>
      <div className="template-container gr">
        {premades.map((value, index) => (
          <div className="template-bx" key={index}>
            <div className="template-img">
              <img className="wd-100" src={value.image} alt="" />
        
            </div>
            <div className="template-text-wrapper">
              <div className="template-header fx al-cnt jstfy-sp-btw" onMouseOver={mouseOverHover} onMouseLeave={mouseLeaveHover}>
                <h2>{value.name}</h2>
              </div>
              <div className="template-right-head">
                <div className="crown-icn flex-cnt">
                  <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.48583 0.90247C9.33358 0.839047 9.16589 0.822377 9.00413 0.85458C8.84237 0.886784 8.69386 0.966405 8.5775 1.0833L7.5 2.1608L5.58917 0.24997C5.43289 0.0937443 5.22097 0.00598145 5 0.00598145C4.77903 0.00598145 4.56711 0.0937443 4.41083 0.24997L2.5 2.1608L1.4225 1.0833C1.30596 0.966796 1.15749 0.887457 0.99586 0.855317C0.834232 0.823178 0.666704 0.83968 0.514455 0.902739C0.362205 0.965797 0.232069 1.07258 0.140499 1.20959C0.0489284 1.3466 3.51869e-05 1.50768 0 1.67247L0 6.0833C0.000661607 6.63563 0.220367 7.16515 0.610925 7.55571C1.00148 7.94627 1.531 8.16598 2.08333 8.16664H7.91667C8.469 8.16598 8.99852 7.94627 9.38908 7.55571C9.77963 7.16515 9.99934 6.63563 10 6.0833V1.67247C10 1.50767 9.95121 1.34655 9.8597 1.20949C9.76818 1.07243 9.63807 0.96559 9.48583 0.90247Z"
                      fill="#FFA000"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {/* <p>{value.description}</p> */}
            <button
              onClick={() => handleTemplateBot(value.url)}
              className={botUrl === value.url ? "temp-bx-slt-btn wd-100 loading" : "temp-bx-slt-btn wd-100"}
            >
              {botUrl === value.url ? "loading" : width <= 1000 ? "View Template" : "Use Template"}
            </button>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
export default Template;
