import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCU6phIQrxTVK5ldrG4juTw1pBu2kecyDs",
  authDomain: "omniengage.firebaseapp.com",
  projectId: "omniengage",
  storageBucket: "omniengage.appspot.com",
  messagingSenderId: "717509625034",
  appId: "1:717509625034:web:e8f14a0838d5117b53a033",
  measurementId: "G-K5X02YNEPT",
};

initializeApp(firebaseConfig);
