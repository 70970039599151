import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./InitialState";
import { deleteCookies } from "../services/cookieService";

export const userSlice = createSlice({
  name: "user",
  initialState: initialState.user,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.userEmail = action.payload.userEmail;
      state.userName = action.payload.userName;
      state.userPhone = action.payload.userPhone;
      state.userId = action.payload.userId;
      state.sessionCount = action.payload.sessionCount;
      state.companyDetails.logo = action.payload.companyDetails.logo;
      state.companyDetails.compName = action.payload.companyDetails.compName;
      state.companyDetails.count = action.payload.companyDetails.count;
      state.companyDetails.createdAt = action.payload.companyDetails.createdAt;
      state.companyDetails.subDomain = action.payload.companyDetails.subDomain;
      state.companyDetails.updatedAt = action.payload.companyDetails.updatedAt;
      state.companyDetails.userId = action.payload.companyDetails.userId;
      state.companyDetails._id = action.payload.companyDetails._id;
      state.defaultCompany = action.payload.defaultCompany;
      state.defaultCompany = action.payload.defaultCompany;
      state.showCoupon = action.payload.showCoupon;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      deleteCookies("token", "/");
      clearInterval(state.trainingIntervalId);
    },
    setInfoModal: (state, action) => {
      state.infoModal.showModal = action.payload.showModal;
      state.infoModal.modalType = action.payload.modalType;
      state.infoModal.modalTitle = action.payload.modalTitle;
      state.infoModal.modalMessage = action.payload.modalMessage;
      state.infoModal.modalButtonText = action.payload.modalButtonText;
      state.infoModal.showModalButton = action.payload.showModalButton;
      state.infoModal.calledFromAgent = action.payload.calledFromAgent;
    },
    setSuccessModal: (state, action) => {
      state.successModal = action.payload;
    },
    setSuccessTitle: (state, action) => {
      state.successTitle = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorModal: (state, action) => {
      state.errorModal = action.payload;
    },
    setErrorTitle: (state, action) => {
      state.errorTitle = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setWarningModal: (state, action) => {
      state.errorModal = action.payload;
    },
    setWarningTitle: (state, action) => {
      state.errorTitle = action.payload;
    },
    setWarningMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setToastPopUp: (state, action) => {
      state.toast.show = action.payload.show;
      state.toast.toastType = action.payload.toastType;
      state.toast.message = action.payload.message;
    },
    setToast: (state, action) => {
      state.showToast = action.payload;
    },
    setToastType: (state, action) => {
      state.toastType = action.payload;
    },
    setToastMessage: (state, action) => {
      state.toastMessage = action.payload;
    },
    setSelectedBotId: (state, action) => {
      state.companyDetails.chats.selectedBotId = action.payload;
    },
    setSelectedBot: (state, action) => {
      state.companyDetails.chats.selectedBot = action.payload;
    },
    setSelectedChat: (state, action) => {
      state.companyDetails.chats.selectedChat = action.payload;
    },
    setSelectedSenderId: (state, action) => {
      state.companyDetails.chats.selectedSenderId = action.payload;
    },
    setCurrentLiveRoomIds: (state, action) => {
      if (!state.companyDetails.chats.liveRoomIds)
        state.companyDetails.chats.liveRoomIds = [];

      if (action.payload.type === "init") {
        state.companyDetails.chats.liveRoomIds = action.payload.roomIds;
      } else if (action.payload.type === "append") {
        state.companyDetails.chats.liveRoomIds = [
          ...state.companyDetails.chats.liveRoomIds,
          ...action.payload.roomIds,
        ];
      } else if (action.payload.type === "remove") {
        state.companyDetails.chats.liveRoomIds =
          state.companyDetails.chats.liveRoomIds.filter(
            (chat: any) => !action.payload.roomIds.includes(chat),
          );
      }
    },
    setReload: (state, action) => {
      state.reload = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setShowConfiguration: (state, action) => {
      state.showConfiguration = action.payload;
    },
    setShowBotAnalytics: (state, action) => {
      state.showBotAnalytics = action.payload;
    },
    setShowSegmentEdit: (state, action) => {
      state.showSegmentEdit = action.payload;
    },
    addValue: (state, action) => {
      state.addClass = action.payload;
      // state.boolValue = !state.boolValue
    },
    closeBool: (state, action) => {
      state.boolValue = action.payload;
    },
    setCompanyDetails: (state, action) => {
      state.companyDetails.compName = action.payload.compName;
      state.companyDetails.logo = action.payload.logo;
      state.companyDetails.count = action.payload.count;
      state.companyDetails.createdAt = action.payload.createdAt;
      state.companyDetails.updatedAt = action.payload.updatedAt;
      state.companyDetails.subDomain = action.payload.subDomain;
      state.companyDetails.userId = action.payload.userId;
      state.companyDetails._id = action.payload.id;
    },
    setCompanyName: (state, action) => {
      state.companyDetails.compName = action.payload.compName;
    },
    setSwitchLoad: (state, action) => {
      state.switchLoad = action.payload;
    },
    closeCoupon: (state, action) => {
      state.showCoupon = action.payload;
    },
    setDebug: (state, action) => {
      state.debug = action.payload;
    },
    setBeta: (state, action) => {
      state.beta = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setBilling: (state, action) => {
      state.billing = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setTrainData: (state, action) => {
      state.trainingPending = action.payload.pending;
      state.trainingProgress = action.payload.progress;
      state.trainingBotId = action.payload.botId;
      if (action.payload.type) state.trainingType = action.payload.type;
    },
    setTrainInterval: (state, action) => {
      clearInterval(state.trainingIntervalId);
      state.trainingIntervalId = action.payload.trainingIntervalId;
    },
    clearTrainInterval: (state) => {
      clearInterval(state.trainingIntervalId);
      state.trainingIntervalId = 0;
      state.trainingPending = false;
      state.trainingProgress = 0;
      state.trainingBotId = "";
    },
    setThrive: (state, action) => {
      state.thrive = action.payload;
    },
    setThriveData: (state, action) => {
      state.thriveData.email = action.payload.email;
      state.thriveData.digest = action.payload.digest;
      state.thriveData.userId = action.payload.userId;
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setShowHelloBar: (state, action) => {
      state.showHelloBar = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userName = action.payload.userName;
      state.userEmail = action.payload.userEmail;
      state.userPhone = action.payload.userPhone;
    },
  },
});

export const { login, addValue } = userSlice.actions;

const userReducer = userSlice.reducer;
export default userReducer;
