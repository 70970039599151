import { Crisp } from "crisp-sdk-web";
import environment from "@/constants/Environment";

const UTM_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

const OMNI_WEBSITE_ID = "92b6694a-fea8-4957-8b52-ec2f6fa49497";
const CBB_WEBSITE_ID = "d46c1117-1192-4647-bfe4-c3a2eea872a3";

const { isAI, ENVIRONMENT } = environment();
const isDevelopment = ENVIRONMENT === "development";

Crisp.configure(isAI ? CBB_WEBSITE_ID : OMNI_WEBSITE_ID, {
  autoload: !isDevelopment,
  sessionMerge: true,
});

Crisp.session.setData(generateSessionData);

function generateSessionData() {
  const sessionData = {};
  const searchParams = new URLSearchParams(window.location.search);

  for (const param of UTM_PARAMS) {
    if (searchParams.has(param)) {
      sessionData[param] = searchParams.get(param);
    }
  }

  return sessionData;
}
