import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./User";
import botReducer from "./Bot";
import environment from "../constants/Environment";
import appReducer from "./IntegrationApps";

const environmentData = environment();

export function createReduxStore() {
  const combinedReducer = combineReducers({
    user: userReducer,
    bot: botReducer,
    apps: appReducer,
  });

  const store = configureStore({
    reducer: combinedReducer,
    preloadedState: loadFromLocalStorage(),
  });

  store.subscribe(() => {
    const state = store.getState();
    if (state.user.isLoggedIn) {
      localStorage.setItem(
        `${environmentData.isAI ? "state_cbb" : "state_omni"}`,
        JSON.stringify(state),
      );
    } else {
      localStorage.removeItem(
        `${environmentData.isAI ? "state_cbb" : "state_omni"}`,
      );
    }
  });

  return store;
}

function loadFromLocalStorage() {
  try {
    const data = JSON.parse(
      localStorage.getItem(
        `${environmentData.isAI ? "state_cbb" : "state_omni"}`,
      ) ?? "{}",
    );
    return data;
  } catch {
    return {};
  }
}
