import { userSlice } from "@/redux/User";
import { axiosGet } from "@/services/axiosService";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function useGetAllCompanies() {
  const dispatch = useDispatch();
  const selectedCompanyId = useSelector(
    (state: any) => state.user.companyDetails._id,
  );

  return useQuery({
    queryKey: ["getAllCompanies"],
    queryFn: async () => {
      const response = await axiosGet(`/users/getAllCompanies`);

      const company = response.data.data.find(
        (comp: any) => comp?.companyId?._id === selectedCompanyId,
      );
      if (!company) dispatch(userSlice.actions.logout());
      else {
        dispatch(userSlice.actions.setRole(company?.role));
      }

      return response.data.data;
    },
    staleTime: 1000,
  });
}
