const Error404_2 = () => (
  <svg
    style={{ height: "300px" }}
    cache-id="c5a5eb1242f2404fb85f5f2e4f9c92b6"
    id="eC0FNawiDyj1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 325 300"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
  >
    <path
      d="M157.837,300c87.171,0,157.838-40.798,157.838-91.125s-70.667-91.125-157.838-91.125C70.6662,117.75,0,158.548,0,208.875s70.6662,91.125,157.837,91.125Z"
      fill="#ebebeb"
    />
    <path
      d="M315.673,208.788c-.036-20.895-12.447-41.782-37.224-58.693l-1.33-.894L54.4746,277.746l1.3741.676C85.1333,292.774,121.412,299.979,157.676,300l157.997-91.212Z"
      fill="#fafafa"
    />
    <path
      d="M122.081,158.841c15.354,0,27.801-7.187,27.801-16.053s-12.447-16.053-27.801-16.053c-15.355,0-27.8017,7.187-27.8017,16.053s12.4467,16.053,27.8017,16.053Z"
      fill="#e0e0e0"
    />
    <path
      d="M209.035,253.55c22.546,0,40.823-10.552,40.823-23.57c0-13.017-18.277-23.57-40.823-23.57-22.545,0-40.822,10.553-40.822,23.57c0,13.018,18.277,23.57,40.822,23.57Z"
      opacity="0.05"
    />
    <path
      d="M47.0926,238.283c17.7394,0,32.12-8.304,32.12-18.547s-14.3806-18.546-32.12-18.546-32.1199,8.303-32.1199,18.546s14.3806,18.547,32.1199,18.547Z"
      fill="#e0e0e0"
    />
    <g clipPath="url(#eC0FNawiDyj18)">
      <g id="eC0FNawiDyj8_to" transform="translate(122.967976,206.900237)">
        <g id="eC0FNawiDyj8_ts" transform="scale(0,0)">
          <g transform="translate(-121.967976,-73.143501)">
            <path
              d="M115.674,64.8511v77.7919c0,.93.618,1.868,1.854,2.581c1.384.699,2.913,1.063,4.464,1.063c1.55,0,3.079-.364,4.464-1.063c1.236-.727,1.854-1.651,1.854-2.581v-77.7919h-12.636Z"
              fill="#b16668"
            />
            <path
              d="M163.813,105.099L124.692,10.6147h-5.453L80.1109,105.128c-.2816.683-.4088,1.419-.3724,2.157.0363.737.2353,1.458.5827,2.11.3474.651.8346,1.218,1.4267,1.659.5921.442,1.2744.747,1.9982.894l.6398.123c1.24.246,2.3407.953,3.0801,1.978.7394,1.026,1.0624,2.293.904,3.548v0c-.1018.828.005,1.669.3108,2.445.3058.777.8008,1.465,1.4401,2.002s1.4026.905,2.2204,1.072c.8179.166,1.6645.126,2.4628-.118l1.9339-.596c1.1616-.358,2.4137-.28,3.522.22c1.108.499,1.996,1.385,2.498,2.492l1.948,4.289c.29.64.712,1.212,1.238,1.678s1.144.816,1.814,1.028c.67.211,1.377.279,2.075.198.698-.08,1.372-.306,1.976-.664l7.59-4.501c.77-.457,1.649-.699,2.545-.699s1.775.242,2.545.699l7.59,4.501c.604.358,1.278.584,1.976.664.698.081,1.405.013,2.075-.198.67-.212,1.288-.562,1.814-1.028s.948-1.038,1.238-1.678l1.941-4.289c.503-1.108,1.392-1.994,2.502-2.493c1.109-.499,2.362-.577,3.525-.219l1.934.589c.797.246,1.644.288,2.462.122.817-.165,1.581-.533,2.22-1.069.639-.537,1.134-1.225,1.439-2.002.304-.777.41-1.617.306-2.445v0c-.155-1.255.17-2.522.911-3.547.74-1.024,1.84-1.731,3.08-1.979l.64-.123c.731-.14,1.422-.442,2.022-.883s1.094-1.011,1.446-1.667.552-1.383.588-2.126c.035-.744-.096-1.486-.385-2.173Z"
              fill="#fb5f66"
            />
            <path
              d="M163.813,105.099L124.692,10.6147h-5.453L80.1109,105.128c-.2816.683-.4088,1.419-.3724,2.157.0363.737.2353,1.458.5827,2.11.3474.651.8346,1.218,1.4267,1.659.5921.442,1.2744.747,1.9982.894l.6398.123c1.24.246,2.3407.953,3.0801,1.978.7394,1.026,1.0624,2.293.904,3.548v0c-.1018.828.005,1.669.3108,2.445.3058.777.8008,1.465,1.4401,2.002s1.4026.905,2.2204,1.072c.8179.166,1.6645.126,2.4628-.118l1.9339-.596c1.1616-.358,2.4137-.28,3.522.22c1.108.499,1.996,1.385,2.498,2.492l1.948,4.289c.29.64.712,1.212,1.238,1.678s1.144.816,1.814,1.028c.67.211,1.377.279,2.075.198.698-.08,1.372-.306,1.976-.664l7.59-4.501c.77-.457,1.649-.699,2.545-.699s1.775.242,2.545.699l7.59,4.501c.604.358,1.278.584,1.976.664.698.081,1.405.013,2.075-.198.67-.212,1.288-.562,1.814-1.028s.948-1.038,1.238-1.678l1.941-4.289c.503-1.108,1.392-1.994,2.502-2.493c1.109-.499,2.362-.577,3.525-.219l1.934.589c.797.246,1.644.288,2.462.122.817-.165,1.581-.533,2.22-1.069.639-.537,1.134-1.225,1.439-2.002.304-.777.41-1.617.306-2.445v0c-.155-1.255.17-2.522.911-3.547.74-1.024,1.84-1.731,3.08-1.979l.64-.123c.731-.14,1.422-.442,2.022-.883s1.094-1.011,1.446-1.667.552-1.383.588-2.126c.035-.744-.096-1.486-.385-2.173Z"
              opacity="0.3"
            />
            <path
              d="M114.871,21.1709l4.362-9.9312h5.453l7.227,16.8307L155.526,85.091c-.113.5994-.412,1.148-.854,1.5681-.443.4201-1.006.6905-1.61.7729-.959.1579-1.82.6787-2.404,1.4544-.585.7756-.849,1.7466-.737,2.7115l.08.727c.076.6443-.015,1.2974-.265,1.8962s-.65,1.123-1.162,1.5221c-.511.399-1.117.6594-1.759.756-.641.0965-1.297.026-1.903-.2047l-1.934-.7271c-.903-.344-1.903-.3292-2.796.0412-.892.3705-1.609,1.0686-2.002,1.9509l-2.4,5.3875c-.219.487-.538.922-.937,1.277s-.869.622-1.378.784c-.509.161-1.046.213-1.577.152s-1.043-.233-1.502-.505l-6.485-4.362c-.586-.3495-1.256-.5341-1.938-.5341s-1.352.1846-1.937.5341l-7.234,4.362c-.46.272-.972.444-1.502.505-.531.061-1.068.009-1.578-.152-.509-.162-.978-.429-1.377-.784s-.719-.79-.938-1.277l-2.399-5.3875c-.394-.8823-1.11-1.5804-2.003-1.9509-.892-.3704-1.892-.3852-2.795-.0412l-1.9342.7271c-.6064.2285-1.2614.2974-1.902.1999s-1.2456-.358-1.7565-.7566c-.511-.3985-.911-.9218-1.1615-1.5194-.2505-.5977-.3432-1.2497-.2692-1.8935l.0873-.727c.1119-.9649-.1519-1.9359-.7366-2.7115-.5848-.7757-1.4457-1.2965-2.4042-1.4544-.4325-.0569-.8463-.2123-1.2093-.4543s-.6656-.5641-.8845-.9416L114.871,21.1709Z"
              opacity="0.1"
            />
            <path
              d="M155.089,77.6323L124.693,6.8418h-5.453L88.0504,77.6323c-1.1196,2.2756-.4798,4.9874,2.0284,5.4018.9585.1579,1.8194.6787,2.4042,1.4543.5847.7757.8485,1.7466.7366,2.7115l-.0873.7271c-.0733.6438.0198,1.2957.2704,1.8933s.6502,1.1209,1.1608,1.5199s1.1151.6603,1.7555.759c.6405.0987,1.2955.0314,1.9025-.1954l1.9335-.727c.903-.344,1.904-.3293,2.796.0412.892.3704,1.609,1.0685,2.003,1.9508l2.399,5.3873c.217.4887.536.9257.935,1.2821.398.3568.868.6238,1.378.7858.51.161,1.048.213,1.579.151s1.043-.236,1.502-.51l7.234-4.3189c.586-.3497,1.256-.5343,1.938-.5343s1.352.1846,1.937.5343l6.486,4.3189c.459.274.971.448,1.502.51s1.069.01,1.579-.151c.51-.162.98-.429,1.378-.7858.399-.3564.718-.7934.935-1.2821l2.399-5.3873c.394-.8823,1.111-1.5804,2.003-1.9508.892-.3705,1.893-.3852,2.796-.0412l1.933.727c.607.229,1.263.2979,1.905.2001.641-.0978,1.246-.359,1.758-.7585.511-.3994.911-.9237,1.161-1.5224.249-.5987.341-1.2517.266-1.896l-.08-.7271c-.112-.9657.151-1.9376.736-2.7144.584-.7768,1.445-1.2991,2.404-1.4587v0c2.56-.4071,3.199-3.1189,2.072-5.3945Z"
              fill="#fb5f66"
            />
            <path
              d="M155.089,77.6323L124.693,6.8418h-5.453L88.0504,77.6323c-1.1196,2.2756-.4798,4.9874,2.0284,5.4018.9585.1579,1.8194.6787,2.4042,1.4543.5847.7757.8485,1.7466.7366,2.7115l-.0873.7271c-.0733.6438.0198,1.2957.2704,1.8933s.6502,1.1209,1.1608,1.5199s1.1151.6603,1.7555.759c.6405.0987,1.2955.0314,1.9025-.1954l1.9335-.727c.903-.344,1.904-.3293,2.796.0412.892.3704,1.609,1.0685,2.003,1.9508l2.399,5.3873c.217.4887.536.9257.935,1.2821.398.3568.868.6238,1.378.7858.51.161,1.048.213,1.579.151s1.043-.236,1.502-.51l7.234-4.3189c.586-.3497,1.256-.5343,1.938-.5343s1.352.1846,1.937.5343l6.486,4.3189c.459.274.971.448,1.502.51s1.069.01,1.579-.151c.51-.162.98-.429,1.378-.7858.399-.3564.718-.7934.935-1.2821l2.399-5.3873c.394-.8823,1.111-1.5804,2.003-1.9508.892-.3705,1.893-.3852,2.796-.0412l1.933.727c.607.229,1.263.2979,1.905.2001.641-.0978,1.246-.359,1.758-.7585.511-.3994.911-.9237,1.161-1.5224.249-.5987.341-1.2517.266-1.896l-.08-.7271c-.112-.9657.151-1.9376.736-2.7144.584-.7768,1.445-1.2991,2.404-1.4587v0c2.56-.4071,3.199-3.1189,2.072-5.3945Z"
              opacity="0.2"
            />
            <path
              d="M119.238,6.8418h5.452l22.153,51.5825c-.172.1511-.36.2828-.56.3926l-.167.0945c-.57.3224-1.036.801-1.342,1.3794-.307.5784-.442,1.2324-.389,1.8849v0c.048.544-.037,1.0913-.245,1.5958-.209.5045-.536.9514-.954,1.3029s-.914.5973-1.447.7166-1.086.1087-1.614-.0311l-1.323-.3489c-.741-.1951-1.525-.1347-2.227.1714-.702.306-1.28.8399-1.641,1.5153l-1.81,3.3806c-.426.789-1.145,1.379-2.002,1.6429-.856.2638-1.783.1803-2.579-.2324l-5.001-2.6173c-.487-.2542-1.029-.3869-1.578-.3869s-1.091.1327-1.578.3869l-5.002,2.6173c-.796.4127-1.722.4962-2.579.2324-.856-.2639-1.575-.8539-2.001-1.6429l-1.81-3.3806c-.362-.676-.942-1.2101-1.645-1.5161s-1.489-.3662-2.23-.1706l-1.316.3489c-.528.1398-1.082.1504-1.614.0311-.533-.1193-1.03-.3651-1.447-.7166-.418-.3515-.7452-.7984-.9539-1.3029-.2088-.5045-.293-1.0518-.2457-1.5958.0543-.6516-.08-1.3052-.3868-1.8827s-.7731-1.0545-1.3435-1.3744l-.1672-.0945c-.3576-.2057-.6753-.4742-.9379-.7924L119.238,6.8418Z"
              opacity="0.1"
            />
            <path
              d="M147.518,49.7869L124.667,1.75231l-.182-.2908c-.254-.44411-.622-.813121-1.065-1.069745s-.945-.391765-1.457-.391765-1.015.135142-1.458.391765-.811.625635-1.065,1.069745l-.182.29808L96.4076,49.7869c-.2419.391-.4021.827-.4709,1.2816-.0688.4547-.0447.9185.0709,1.3636.1155.4451.32.8621.6012,1.226s.6332.6668,1.0348.8908l.1672.0945c.5697.3205,1.0356.7977,1.3423,1.3751.3067.5773.4414,1.2305.388,1.882-.0473.5439.0369,1.0913.2456,1.5958.2088.5045.5363.9514.9533,1.3029.418.3515.914.5973,1.447.7166s1.087.1087,1.614-.0311l1.316-.3489c.742-.1943,1.527-.1335,2.23.1724s1.282.8392,1.645,1.5143l1.811,3.3806c.424.7904,1.143,1.3816,2,1.6456.858.2641,1.785.1796,2.58-.2351l5.002-2.6101c.487-.2541,1.028-.3869,1.578-.3869.549,0,1.09.1328,1.577.3869l5.002,2.6101c.796.4147,1.723.4992,2.58.2351.857-.264,1.576-.8552,2-1.6456l1.811-3.3806c.362-.6745.94-1.2076,1.641-1.5135.702-.306,1.486-.367,2.226-.1732l1.324.3489c.527.1398,1.081.1504,1.614.0311s1.029-.3651,1.447-.7166.745-.7984.953-1.3029c.209-.5045.293-1.0519.246-1.5958v0c-.053-.6515.081-1.3047.388-1.882.307-.5774.773-1.0546,1.342-1.3751l.168-.0945c.401-.224.753-.527,1.034-.8908.282-.3639.486-.7809.602-1.226.115-.4451.139-.9089.07-1.3636-.068-.4546-.228-.8906-.47-1.2816v0Z"
              fill="#fb5f66"
            />
            <path
              d="M147.518,49.7869L124.667,1.75231l-.182-.2908c-.254-.44411-.622-.813121-1.065-1.069745s-.945-.391765-1.457-.391765-1.015.135142-1.458.391765-.811.625635-1.065,1.069745l-.182.29808L96.4076,49.7869c-.2419.391-.4021.827-.4709,1.2816-.0688.4547-.0447.9185.0709,1.3636.1155.4451.32.8621.6012,1.226s.6332.6668,1.0348.8908l.1672.0945c.5697.3205,1.0356.7977,1.3423,1.3751.3067.5773.4414,1.2305.388,1.882-.0473.5439.0369,1.0913.2456,1.5958.2088.5045.5363.9514.9533,1.3029.418.3515.914.5973,1.447.7166s1.087.1087,1.614-.0311l1.316-.3489c.742-.1943,1.527-.1335,2.23.1724s1.282.8392,1.645,1.5143l1.811,3.3806c.424.7904,1.143,1.3816,2,1.6456.858.2641,1.785.1796,2.58-.2351l5.002-2.6101c.487-.2541,1.028-.3869,1.578-.3869.549,0,1.09.1328,1.577.3869l5.002,2.6101c.796.4147,1.723.4992,2.58.2351.857-.264,1.576-.8552,2-1.6456l1.811-3.3806c.362-.6745.94-1.2076,1.641-1.5135.702-.306,1.486-.367,2.226-.1732l1.324.3489c.527.1398,1.081.1504,1.614.0311s1.029-.3651,1.447-.7166.745-.7984.953-1.3029c.209-.5045.293-1.0519.246-1.5958v0c-.053-.6515.081-1.3047.388-1.882.307-.5774.773-1.0546,1.342-1.3751l.168-.0945c.401-.224.753-.527,1.034-.8908.282-.3639.486-.7809.602-1.226.115-.4451.139-.9089.07-1.3636-.068-.4546-.228-.8906-.47-1.2816v0Z"
              opacity="0.1"
            />
          </g>
        </g>
      </g>
      <clipPath id="eC0FNawiDyj18" transform="translate(0-144)">
        <rect
          width="157.572656"
          height="104.584025"
          rx="0"
          ry="0"
          transform="matrix(1 0 0 1.380906 47.315726 143.781436)"
          fill="#d2dbed"
          strokeWidth="0"
        />
      </clipPath>
    </g>
    <path
      d="M118.919,145.057c-.043-1-.47-1.944-1.193-2.636s-1.685-1.079-2.685-1.079c-1.001,0-1.963.387-2.685,1.079-.723.692-1.151,1.636-1.194,2.636v.218c.057.316.191.613.388.866.198.254.454.455.746.588.851.43,1.791.654,2.745.654.953,0,1.893-.224,2.744-.654.292-.134.548-.336.745-.589s.331-.55.389-.865v-.218v0Z"
      fill="#37474f"
    />
    <path
      d="M117.787,146.75c.292-.133.547-.335.745-.588.197-.253.331-.55.389-.866v-.218c-.019-.678-.216-1.339-.57-1.917-.355-.577-.855-1.052-1.451-1.376c0,.043,1.934,2.435.524,4.035-.975,1.105-4.363,1.308-5.817.436.194.207.42.381.669.516.856.43,1.801.653,2.758.649.958-.004,1.901-.234,2.753-.671v0Z"
      fill="#263238"
    />
    <path
      d="M112.646,147.012c-.036-.702-.34-1.363-.849-1.846-.51-.484-1.185-.754-1.888-.754-.702,0-1.378.27-1.887.754-.51.483-.814,1.144-.85,1.846v.153c.038.225.131.437.271.619.139.181.321.325.529.42.6.305,1.264.464,1.937.464s1.337-.159,1.938-.464c.208-.095.389-.239.528-.42.14-.182.233-.394.271-.619.004-.027.004-.054,0-.08.004-.025.004-.049,0-.073v0Z"
      fill="#455a64"
    />
    <path
      d="M111.846,148.205c.208-.095.389-.24.528-.421.14-.181.233-.394.271-.619.004-.027.004-.053,0-.08.004-.024.004-.049,0-.073-.007-.311-.069-.618-.181-.908.065,1.17-.487,1.883-2.218,2.108-1.73.225-2.864-.516-3.01-.785.144.336.401.612.727.778.602.305,1.267.465,1.942.465.674,0,1.339-.16,1.941-.465Z"
      fill="#37474f"
    />
    <path
      d="M132.732,144.271c-.036-.696-.337-1.351-.843-1.831-.505-.48-1.175-.748-1.872-.748s-1.367.268-1.872.748c-.506.48-.807,1.135-.843,1.831-.004.024-.004.049,0,.073-.004.026-.004.053,0,.08.035.224.127.436.265.616s.319.323.527.416c.596.301,1.255.458,1.923.458s1.327-.157,1.923-.458c.206-.094.386-.238.525-.418.138-.18.23-.391.267-.614v-.153Z"
      fill="#455a64"
    />
    <g transform="translate(.000009 58.000006)" clipPath="url(#eC0FNawiDyj36)">
      <g id="eC0FNawiDyj26_to" transform="translate(46.677186,249.399209)">
        <g id="eC0FNawiDyj26_ts" transform="rotate(-0.645154) scale(0,0)">
          <g transform="translate(-46.677186,-146.399208)">
            <path
              d="M53.5115,136.15v84.037c0,1.011-.6689,2.021-1.9993,2.792-1.4968.757-3.1504,1.151-4.8275,1.151-1.677,0-3.3307-.394-4.8274-1.151-1.3305-.771-1.9921-1.781-1.9921-2.792v-84.037h13.6463Z"
              fill="#b16668"
            />
            <path
              d="M1.46506,179.648L43.7416,77.5737h5.8889L91.9143,179.692c.3056.738.4436,1.535.4042,2.333-.0395.798-.2554,1.577-.6323,2.281-.3769.705-.9054,1.317-1.5473,1.792-.642.476-1.3814.803-2.1651.959l-.727.138c-1.3394.267-2.5283,1.03-3.328,2.137s-1.1509,2.476-.9832,3.832v0c.1089.894-.0075,1.801-.3386,2.638-.331.838-.8662,1.579-1.557,2.157s-1.5149.974-2.3978,1.153c-.8828.178-1.7962.133-2.657-.132l-2.0866-.64c-1.2576-.398-2.6173-.322-3.8227.214s-2.173,1.494-2.7205,2.694l-2.1011,4.638c-.3137.692-.7696,1.31-1.3382,1.813-.5685.504-1.2368.882-1.9612,1.11-.7245.227-1.4889.3-2.2432.212-.7544-.087-1.4818-.333-2.1348-.721l-8.1354-4.864c-.8348-.492-1.7863-.752-2.7554-.752-.9692,0-1.9207.26-2.7555.752L35.7007,208.3c-.653.388-1.3804.634-2.1348.721-.7543.088-1.5187.015-2.2432-.212-.7244-.228-1.3927-.606-1.9612-1.11-.5686-.503-1.0245-1.121-1.3382-1.813l-2.1011-4.638c-.5475-1.2-1.5152-2.158-2.7205-2.694s-2.5651-.612-3.8227-.214l-2.0939.64c-.8608.265-1.7742.31-2.657.132-.8829-.179-1.7071-.575-2.3978-1.153s-1.226-1.319-1.557-2.157c-.3311-.837-.4474-1.744-.3385-2.638v0c.1714-1.355-.1775-2.725-.97627-3.833-.79879-1.107-1.98803-1.871-3.32775-2.136l-.72703-.138c-.78017-.167-1.51379-.504-2.14814-.988s-1.15369-1.102-1.5207-1.81-.57258-1.489-.60195-2.286c-.02937-.798.1182-1.591.4321-2.325v0Z"
              fill="#fb5f66"
            />
            <path
              d="M1.46506,179.648L43.7416,77.5737h5.8889L91.9143,179.692c.3056.738.4436,1.535.4042,2.333-.0395.798-.2554,1.577-.6323,2.281-.3769.705-.9054,1.317-1.5473,1.792-.642.476-1.3814.803-2.1651.959l-.727.138c-1.3394.267-2.5283,1.03-3.328,2.137s-1.1509,2.476-.9832,3.832v0c.1089.894-.0075,1.801-.3386,2.638-.331.838-.8662,1.579-1.557,2.157s-1.5149.974-2.3978,1.153c-.8828.178-1.7962.133-2.657-.132l-2.0866-.64c-1.2576-.398-2.6173-.322-3.8227.214s-2.173,1.494-2.7205,2.694l-2.1011,4.638c-.3137.692-.7696,1.31-1.3382,1.813-.5685.504-1.2368.882-1.9612,1.11-.7245.227-1.4889.3-2.2432.212-.7544-.087-1.4818-.333-2.1348-.721l-8.1354-4.864c-.8348-.492-1.7863-.752-2.7554-.752-.9692,0-1.9207.26-2.7555.752L35.7007,208.3c-.653.388-1.3804.634-2.1348.721-.7543.088-1.5187.015-2.2432-.212-.7244-.228-1.3927-.606-1.9612-1.11-.5686-.503-1.0245-1.121-1.3382-1.813l-2.1011-4.638c-.5475-1.2-1.5152-2.158-2.7205-2.694s-2.5651-.612-3.8227-.214l-2.0939.64c-.8608.265-1.7742.31-2.657.132-.8829-.179-1.7071-.575-2.3978-1.153s-1.226-1.319-1.557-2.157c-.3311-.837-.4474-1.744-.3385-2.638v0c.1714-1.355-.1775-2.725-.97627-3.833-.79879-1.107-1.98803-1.871-3.32775-2.136l-.72703-.138c-.78017-.167-1.51379-.504-2.14814-.988s-1.15369-1.102-1.5207-1.81-.57258-1.489-.60195-2.286c-.02937-.798.1182-1.591.4321-2.325v0Z"
              opacity="0.3"
            />
            <path
              d="M54.3574,88.9443L49.6318,78.2134h-5.8889L35.9346,96.389L10.416,158.012c.1234.646.4467,1.237.9241,1.69.4774.452,1.085.744,1.7368.832v0c1.0362.173,1.9666.737,2.5986,1.576s.9172,1.889.7966,2.932l-.0872.785c-.0808.697.0193,1.403.2906,2.05.2713.648.7047,1.214,1.2585,1.645.5537.431,1.209.712,1.9029.816s1.403.028,2.0588-.221l2.0866-.8c.98-.37,2.0645-.35,3.0301.056s1.739,1.166,2.1609,2.125l2.5882,5.816c.2342.527.5775.999,1.0072,1.383.4297.385.9361.674,1.4857.848.5496.175,1.13.231,1.7029.164.5728-.066,1.1251-.253,1.6204-.548l7.0158-4.668c.6317-.378,1.3541-.577,2.0902-.577s1.4585.199,2.0902.577l7.83,4.668c.4956.294,1.0478.48,1.6204.546.5726.065,1.1525.009,1.7018-.165.5493-.175,1.0555-.463,1.4854-.847s.7736-.855,1.0087-1.381l2.5882-5.816c.4219-.959,1.1952-1.719,2.1608-2.125s2.0501-.426,3.0301-.056l2.0866.8c.6559.249,1.3649.325,2.0588.221s1.3493-.385,1.903-.816.9871-.997,1.2584-1.645c.2713-.647.3714-1.353.2906-2.05l-.0872-.785c-.1206-1.043.1647-2.093.7967-2.932.6319-.839,1.5623-1.403,2.5985-1.576.4671-.06.914-.227,1.3061-.488s.7189-.609.955-1.016L54.3574,88.9443Z"
              opacity="0.1"
            />
            <path
              d="M10.886,149.956L43.7403,73.4585h5.8889L83.305,149.956c1.2069,2.465.5162,5.395-2.1811,5.816-1.0362.173-1.9665.736-2.5985,1.576-.632.839-.9172,1.888-.7967,2.932l.0873.778c.0807.697-.0193,1.403-.2907,2.05-.2713.647-.7047,1.213-1.2584,1.644s-1.2091.713-1.903.817-1.4029.028-2.0588-.222l-2.0865-.792c-.9801-.37-2.0645-.35-3.0301.056-.9657.406-1.739,1.166-2.1609,2.125l-2.5882,5.816c-.236.526-.5803.995-1.0102,1.378-.43.384-.936.672-1.4849.846s-1.1286.231-1.7009.166-1.1244-.25-1.6202-.543l-7.8374-4.675c-.6317-.378-1.354-.578-2.0902-.578-.7361,0-1.4584.2-2.0902.578l-7.0158,4.675c-.4958.293-1.0479.478-1.6202.543s-1.1519.008-1.7008-.166-1.055-.462-1.485-.846c-.4299-.383-.7741-.852-1.0102-1.378l-2.5882-5.816c-.4219-.959-1.1952-1.719-2.1608-2.125s-2.0501-.426-3.0301-.056l-2.1084.814c-.6559.249-1.3649.326-2.0588.222-.6939-.105-1.3493-.386-1.903-.817s-.9871-.997-1.2584-1.644-.3714-1.353-.2907-2.05l.0873-.786c.1204-1.042-.1651-2.091-.7972-2.929s-1.5624-1.4-2.598-1.571v0c-2.6973-.429-3.39523-3.381-2.1811-5.838Z"
              fill="#fb5f66"
            />
            <path
              d="M10.886,149.956L43.7403,73.4585h5.8889L83.305,149.956c1.2069,2.465.5162,5.395-2.1811,5.816-1.0362.173-1.9665.736-2.5985,1.576-.632.839-.9172,1.888-.7967,2.932l.0873.778c.0807.697-.0193,1.403-.2907,2.05-.2713.647-.7047,1.213-1.2584,1.644s-1.2091.713-1.903.817-1.4029.028-2.0588-.222l-2.0865-.792c-.9801-.37-2.0645-.35-3.0301.056-.9657.406-1.739,1.166-2.1609,2.125l-2.5882,5.816c-.236.526-.5803.995-1.0102,1.378-.43.384-.936.672-1.4849.846s-1.1286.231-1.7009.166-1.1244-.25-1.6202-.543l-7.8374-4.675c-.6317-.378-1.354-.578-2.0902-.578-.7361,0-1.4584.2-2.0902.578l-7.0158,4.675c-.4958.293-1.0479.478-1.6202.543s-1.1519.008-1.7008-.166-1.055-.462-1.485-.846c-.4299-.383-.7741-.852-1.0102-1.378l-2.5882-5.816c-.4219-.959-1.1952-1.719-2.1608-2.125s-2.0501-.426-3.0301-.056l-2.1084.814c-.6559.249-1.3649.326-2.0588.222-.6939-.105-1.3493-.386-1.903-.817s-.9871-.997-1.2584-1.644-.3714-1.353-.2907-2.05l.0873-.786c.1204-1.042-.1651-2.091-.7972-2.929s-1.5624-1.4-2.598-1.571v0c-2.6973-.429-3.39523-3.381-2.1811-5.838Z"
              opacity="0.2"
            />
            <path
              d="M49.6326,73.4585h-5.8889L19.8027,129.192c.1861.161.3884.303.6035.422l.1817.102c.6177.345,1.1225.862,1.4539,1.487.3314.626.4754,1.334.4146,2.039v0c-.0496.588.0426,1.179.269,1.724s.5803,1.027,1.032,1.407.9879.646,1.5636.775s1.174.119,1.7447-.031l1.4541-.378c.7996-.213,1.6476-.149,2.4064.181s1.3839.907,1.774,1.636l1.9629,3.636c.4569.854,1.2329,1.493,2.159,1.777.926.285,1.927.192,2.7848-.258l5.4091-2.821c.525-.279,1.1104-.424,1.7049-.424.5944,0,1.1798.145,1.7048.424l5.4018,2.821c.8593.449,1.8607.541,2.7874.256.9266-.284,1.704-.922,2.1637-1.775l1.9557-3.636c.3923-.729,1.0189-1.305,1.7786-1.635.7597-.329,1.6083-.394,2.409-.182l1.4541.378c.5704.15,1.1685.161,1.744.032.5754-.13,1.1113-.396,1.5625-.776.4512-.379.8044-.862,1.0299-1.407s.3166-1.137.2657-1.724c-.0585-.704.0866-1.41.4178-2.034.3313-.624.8348-1.139,1.4506-1.485l.1891-.109c.3858-.217.7264-.505,1.0032-.851L49.6326,73.4585Z"
              opacity="0.1"
            />
            <path
              d="M19.0754,119.872L43.7942,67.9624l.1891-.3126c.2747-.4804.6716-.8797,1.1504-1.1575.4788-.2777,1.0224-.4239,1.5759-.4239s1.0971.1462,1.5759.4239c.4788.2778.8757.6771,1.1504,1.1575l.2036.3199L74.3584,119.872c.2606.423.4331.894.5072,1.385s.0483.991-.076,1.472c-.1242.481-.3443.932-.6471,1.325-.3027.394-.6818.722-1.1146.965l-.189.102c-.6158.346-1.1194.862-1.4506,1.485-.3312.624-.4763,1.33-.4179,2.034.051.588-.0401,1.179-.2656,1.724s-.5787,1.028-1.0299,1.408-.9871.645-1.5626.775c-.5754.129-1.1736.118-1.7439-.032l-1.4541-.378c-.8007-.212-1.6493-.147-2.409.182-.7598.33-1.3863.906-1.7786,1.636L58.771,137.59c-.4597.853-1.2371,1.491-2.1637,1.775-.9267.285-1.9281.193-2.7874-.256l-5.4018-2.821c-.525-.278-1.1104-.424-1.7049-.424s-1.1798.146-1.7048.424l-5.4091,2.821c-.8578.45-1.8588.543-2.7849.259-.926-.285-1.702-.924-2.1589-1.778l-1.963-3.635c-.39-.73-1.0151-1.307-1.7739-1.637-.7588-.329-1.6068-.393-2.4065-.181l-1.454.378c-.5707.15-1.1691.161-1.7448.031-.5757-.129-1.1119-.395-1.5635-.775-.4517-.379-.8056-.862-1.032-1.407s-.3187-1.136-.269-1.724v0c.0649-.705-.0774-1.413-.4093-2.038s-.839-1.14-1.4592-1.481l-.1818-.109c-.4319-.243-.8102-.571-1.1121-.964-.3019-.394-.5213-.844-.6449-1.324s-.1488-.98-.0743-1.47.2474-.96.5082-1.382v0Z"
              fill="#fb5f66"
            />
            <path
              d="M19.0754,119.872L43.7942,67.9624l.1891-.3126c.2747-.4804.6716-.8797,1.1504-1.1575.4788-.2777,1.0224-.4239,1.5759-.4239s1.0971.1462,1.5759.4239c.4788.2778.8757.6771,1.1504,1.1575l.2036.3199L74.3584,119.872c.2606.423.4331.894.5072,1.385s.0483.991-.076,1.472c-.1242.481-.3443.932-.6471,1.325-.3027.394-.6818.722-1.1146.965l-.189.102c-.6158.346-1.1194.862-1.4506,1.485-.3312.624-.4763,1.33-.4179,2.034.051.588-.0401,1.179-.2656,1.724s-.5787,1.028-1.0299,1.408-.9871.645-1.5626.775c-.5754.129-1.1736.118-1.7439-.032l-1.4541-.378c-.8007-.212-1.6493-.147-2.409.182-.7598.33-1.3863.906-1.7786,1.636L58.771,137.59c-.4597.853-1.2371,1.491-2.1637,1.775-.9267.285-1.9281.193-2.7874-.256l-5.4018-2.821c-.525-.278-1.1104-.424-1.7049-.424s-1.1798.146-1.7048.424l-5.4091,2.821c-.8578.45-1.8588.543-2.7849.259-.926-.285-1.702-.924-2.1589-1.778l-1.963-3.635c-.39-.73-1.0151-1.307-1.7739-1.637-.7588-.329-1.6068-.393-2.4065-.181l-1.454.378c-.5707.15-1.1691.161-1.7448.031-.5757-.129-1.1119-.395-1.5635-.775-.4517-.379-.8056-.862-1.032-1.407s-.3187-1.136-.269-1.724v0c.0649-.705-.0774-1.413-.4093-2.038s-.839-1.14-1.4592-1.481l-.1818-.109c-.4319-.243-.8102-.571-1.1121-.964-.3019-.394-.5213-.844-.6449-1.324s-.1488-.98-.0743-1.47.2474-.96.5082-1.382v0Z"
              opacity="0.1"
            />
          </g>
        </g>
      </g>
      <clipPath id="eC0FNawiDyj36" transform="matrix(.962314 0 0 1.179574-.000016-188.513077)">
        <rect
          width="94.2793"
          height="203.717414"
          rx="0"
          ry="0"
          transform="matrix(1 0 0 0.787792 0 139.513082)"
          fill="#d2dbed"
          strokeWidth="0"
        />
      </clipPath>
    </g>
    <g transform="translate(2-5)">
      <path
        d="M49.9785,222.826c.0451-1.081.5063-2.104,1.2874-2.853s1.8216-1.167,2.9039-1.167s2.1228.418,2.9039,1.167s1.2424,1.772,1.2874,2.853v.233c-.0602.345-.2026.67-.4152.949-.2126.278-.4891.501-.8062.65-.9189.467-1.9353.711-2.9662.711s-2.0474-.244-2.9663-.711c-.3184-.148-.5964-.371-.8103-.649-.2139-.279-.3575-.605-.4184-.95v-.233Z"
        fill="#37474f"
      />
      <path
        d="M51.2072,224.658c-.3184-.148-.5964-.37-.8103-.649-.2139-.278-.3575-.604-.4184-.95v-.233c.0212-.731.2338-1.444.6165-2.068s.9221-1.137,1.5646-1.487c0,.036-2.0866,2.625-.5598,4.362c1.0542,1.192,4.6748,1.411,6.2597.473-.2166.218-.4611.406-.727.56-.9186.465-1.934.706-2.9636.705-1.0295-.001-2.0443-.245-2.9617-.713v0Z"
        fill="#263238"
      />
      <path
        d="M56.7871,224.935c0-.785.3118-1.538.8667-2.093s1.3075-.866,2.0923-.866s1.5374.311,2.0923.866.8667,1.308.8667,2.093c.0033.026.0033.053,0,.08.0036.029.0036.058,0,.087-.0403.244-.1403.475-.2912.672-.151.196-.3481.353-.574.455-.6487.329-1.3661.501-2.0938.501s-1.445-.172-2.0938-.501c-.2241-.105-.4196-.262-.5702-.458s-.2518-.426-.295-.669v-.167Z"
        fill="#455a64"
      />
      <path
        d="M57.6171,226.229c-.224-.105-.4196-.262-.5701-.458s-.2518-.426-.295-.669v-.167c.0114-.337.0802-.669.2035-.982-.0727,1.272.5235,2.036,2.3992,2.276s3.0899-.553,3.2498-.844c-.1588.367-.442.666-.7997.844-.6488.329-1.3662.501-2.0939.501s-1.445-.172-2.0938-.501v0Z"
        fill="#37474f"
      />
      <path
        d="M35.0561,221.976c0-.778.3091-1.525.8592-2.075s1.2963-.859,2.0743-.859s1.5242.309,2.0744.859.8592,1.297.8592,2.075c.0097.052.0097.107,0,.159-.0363.245-.134.475-.2841.671s-.3475.351-.5738.449c-.6432.326-1.3544.497-2.0757.497s-1.4324-.171-2.0756-.497c-.2236-.102-.4187-.258-.5682-.453s-.2491-.424-.2897-.667c-.0097-.052-.0097-.107,0-.159Z"
        fill="#455a64"
      />
      <path
        d="M35.914,223.255c-.2236-.102-.4187-.258-.5682-.453s-.2491-.424-.2897-.666c-.0097-.053-.0097-.107,0-.16.0076-.334.074-.664.1963-.975-.0727,1.258.5235,2.022,2.3774,2.262c1.8539.239,3.068-.553,3.228-.844-.1584.363-.4388.659-.7925.836-.6432.327-1.3544.497-2.0757.497s-1.4324-.17-2.0756-.497v0Z"
        fill="#37474f"
      />
    </g>
    <path
      d="M131.939,145.456c.207-.094.387-.237.525-.417s.23-.391.268-.615v-.153c-.007-.309-.069-.614-.182-.901.065,1.163-.487,1.868-2.181,2.086s-2.843-.509-2.988-.77c.146.333.403.605.727.77.594.299,1.25.454,1.916.454.665,0,1.321-.155,1.915-.454v0Z"
      fill="#37474f"
    />
    <path
      d="M137.337,229.762l4.26-2.435c0,0,2.043-11.255.516-20.931-2.181-13.603-11.407-18.292-14.773-16.94s-4.151,5.38,1.919,13.239c3.585,4.639,8.223,14.468,8.078,27.067Z"
      fill="#fb5f66"
    />
    <path
      d="M137.337,229.762l4.26-2.435c0,0,2.043-11.255.516-20.931-2.181-13.603-11.407-18.292-14.773-16.94s-4.151,5.38,1.919,13.239c3.585,4.639,8.223,14.468,8.078,27.067Z"
      opacity="0.3"
    />
    <path
      d="M138.887,224.361h-.043c-.08-.011-.153-.053-.202-.117s-.07-.145-.06-.225c1.759-12.738-3.359-26.689-9.117-32.309-.056-.057-.088-.134-.088-.215c0-.08.032-.157.088-.214.028-.029.061-.052.098-.067.037-.016.076-.024.116-.024s.08.008.117.024c.037.015.07.038.098.067c5.86,5.729,11.08,19.906,9.291,32.832-.014.069-.052.131-.106.176s-.122.07-.192.072Z"
      fill="#fff"
    />
    <path
      d="M125.522,236.582c-.669.392-2.058-.458-2.588-.814-.912-.619-1.648-1.463-2.138-2.45-.553-1.127-.738-2.398-.531-3.636.106-.65.259-1.291.458-1.919.175-.625.357-1.243.531-1.868.221-.602.316-1.243.276-1.883-.159-1.273-1.294-2.181-2.326-2.909-1.032-.727-2.326-1.977-2.319-3.511.084-1.193.559-2.325,1.352-3.221.727-.93,1.607-1.752,2.276-2.733.521-.684.793-1.526.77-2.385-.074-.526-.249-1.032-.516-1.49-.625-.951-1.172-1.95-1.636-2.989-.222-.525-.292-1.103-.2-1.666.091-.563.339-1.09.716-1.518.376-.278.804-.48,1.258-.592s.926-.133,1.389-.062c.872.107,1.71.402,2.457.865c2.436,1.352,3.832,4.246,6.42,5.285c1.024.325,2.068.58,3.126.764.531.099,1.036.308,1.481.614.446.305.823.7,1.107,1.16.945,1.897-.814,4.231-.145,6.245.589,1.781,2.319,2.465,3.293,4.071.396.71.554,1.528.451,2.334-.086,1.362-.418,2.697-.981,3.94-.637,1.239-1.366,2.427-2.182,3.556-.021.029-11.69,6.754-11.799,6.812Z"
      fill="#fb5f66"
    />
    <path
      d="M125.522,236.582c-.669.392-2.058-.458-2.588-.814-.912-.619-1.648-1.463-2.138-2.45-.553-1.127-.738-2.398-.531-3.636.106-.65.259-1.291.458-1.919.175-.625.357-1.243.531-1.868.221-.602.316-1.243.276-1.883-.159-1.273-1.294-2.181-2.326-2.909-1.032-.727-2.326-1.977-2.319-3.511.084-1.193.559-2.325,1.352-3.221.727-.93,1.607-1.752,2.276-2.733.521-.684.793-1.526.77-2.385-.074-.526-.249-1.032-.516-1.49-.625-.951-1.172-1.95-1.636-2.989-.222-.525-.292-1.103-.2-1.666.091-.563.339-1.09.716-1.518.376-.278.804-.48,1.258-.592s.926-.133,1.389-.062c.872.107,1.71.402,2.457.865c2.436,1.352,3.832,4.246,6.42,5.285c1.024.325,2.068.58,3.126.764.531.099,1.036.308,1.481.614.446.305.823.7,1.107,1.16.945,1.897-.814,4.231-.145,6.245.589,1.781,2.319,2.465,3.293,4.071.396.71.554,1.528.451,2.334-.086,1.362-.418,2.697-.981,3.94-.637,1.239-1.366,2.427-2.182,3.556-.021.029-11.69,6.754-11.799,6.812Z"
      opacity="0.15"
    />
    <path
      d="M132.433,232.903c-.081-.004-.158-.039-.214-.099s-.086-.139-.084-.221c.502-12.054-5.176-25.358-9.306-29.226-.028-.028-.051-.061-.067-.098s-.024-.077-.024-.117.008-.079.024-.116.039-.07.067-.098c.058-.057.135-.088.215-.088s.157.031.214.088c4.217,3.948,10.011,17.448,9.503,29.699-.01.079-.049.151-.109.203-.061.051-.139.077-.219.073v0Z"
      fill="#fff"
    />
    <path
      d="M130.632,218.661c-.031.006-.063.006-.094,0-2.849-.784-5.774-1.257-8.725-1.411-.043.007-.088.004-.13-.008-.043-.012-.082-.033-.115-.062-.034-.028-.061-.064-.079-.104-.019-.04-.028-.084-.028-.128s.009-.087.028-.127c.018-.04.045-.076.079-.105.033-.028.072-.049.115-.061.042-.012.087-.015.13-.008c3.01.161,5.994.648,8.899,1.454.076.026.14.081.178.152.037.072.047.155.026.233-.026.053-.066.098-.116.129-.051.031-.109.047-.168.046v0Z"
      fill="#fff"
    />
    <g clipPath="url(#eC0FNawiDyj73)">
      <g id="eC0FNawiDyj54_to" transform="translate(289.430647,272.629193)">
        <g transform="translate(-293.638504,-121.209995)">
          <path
            d="M296.371,52.208c-.028-.2361-.117-.4611-.257-.6533s-.327-.3453-.543-.4445c-.605-.3021-1.272-.4593-1.948-.4593-.677,0-1.344.1572-1.949.4593-.217.0988-.405.2516-.546.4438-.142.1922-.231.4173-.261.654v0v138.011c0,.858,1.229,1.556,2.748,1.556c1.52,0,2.756-.727,2.756-1.556v-138.011v0Z"
            fill="#37474f"
          />
          <path
            d="M293.611,53.7712c1.522,0,2.755-.6998,2.755-1.5631s-1.233-1.5631-2.755-1.5631-2.756.6998-2.756,1.5631s1.234,1.5631,2.756,1.5631Z"
            fill="#455a64"
          />
          <path d="M324.752,104.786l-5.402-9.3637-35.93-20.7275-2.719,1.5704v12.4758l35.93,20.727l2.77-1.526l5.351-3.156Z" fill="#fb5f66" />
          <path d="M280.701,76.2651l35.93,20.7275l5.402,9.3564-5.402,3.119-35.93-20.7271v-12.4758Z" fill="#fb5f66" />
          <g opacity="0.3">
            <path d="M280.703,76.2656l35.93,20.7275l5.416,9.3859-5.416,3.09-35.93-20.7276v-12.4758Z" />
          </g>
          <path d="M319.35,95.4223l-2.719,1.5704-35.93-20.7275l2.719-1.5704l35.93,20.7275Z" opacity="0.1" />
          <path d="M316.631,96.9927l2.719-1.5703l5.402,9.3636-2.705,1.593-5.416-9.3863Z" opacity="0.2" />
          <path d="M312.939,72.9938L277.009,52.23l-5.402,3.1189l5.402,9.3641l35.93,20.7566v-12.4758Z" fill="#fb5f66" />
          <path d="M277.01,52.2299l2.639-1.5849l35.988,20.7929-2.698,1.5559L277.01,52.2299Z" fill="#fb5f66" />
          <g opacity="0.4">
            <path d="M277.004,52.2299l2.639-1.5849l35.988,20.7929-2.698,1.5559-35.929-20.7639Z" fill="#fff" />
          </g>
          <path d="M315.637,83.9137l-2.698,1.5559v-12.4758l2.698-1.5558v12.4757Z" fill="#fb5f66" />
          <path d="M315.637,83.9137l-2.698,1.5559v-12.4758l2.698-1.5558v12.4757Z" opacity="0.1" />
          <path d="M303.857,111.555l-35.93-20.7635-5.402,3.1189l5.402,9.3646l35.93,20.756v-12.476Z" fill="#fb5f66" />
          <path d="M267.928,90.7915l2.646-1.585l35.981,20.7925-2.698,1.556-35.929-20.7635Z" fill="#fb5f66" />
          <path d="M306.555,122.475l-2.698,1.556v-12.476L306.555,110v12.475Z" opacity="0.1" />
          <path d="M306.555,122.475l-2.698,1.556v-12.476L306.555,110v12.475Z" fill="#fb5f66" />
        </g>
      </g>
      <clipPath id="eC0FNawiDyj73" transform="matrix(1 0 0 0.794277 0-103)">
        <rect width="173.76248" height="203.959072" rx="0" ry="0" transform="translate(151.23752 180.14)" fill="#d2dbed" strokeWidth="0" />
      </clipPath>
    </g>
    <g id="eC0FNawiDyj75_to" transform="translate(223.676659,59.065587)">
      <path
        id="eC0FNawiDyj75"
        d="M204.115,18.6194c1.219-.4041,2.49-.6318,3.773-.6762c1.231-.0543,2.462.1106,3.635.4871c1.12.3651,2.149.9649,3.018,1.7594.79.7891,1.407,1.7333,1.814,2.7732s.594,2.1527.548,3.2684c-.065.86-.259,1.7055-.574,2.5082-.302.7812-.674,1.5332-1.112,2.2466-.437.727-.851,1.3886-1.236,2.0284-.373.607-.69,1.2471-.945,1.912-.236.588-.304,1.2297-.197,1.8539.046.293.008.5927-.109.8652-.051.1326-.132.2515-.236.3474-.105.0959-.231.1661-.367.2051l-2.995.9888c-.143.047-.294.0654-.443.0542-.15-.0112-.296-.0519-.43-.1196-.136-.0566-.256-.1449-.351-.2574-.095-.1126-.161-.2462-.194-.3897-.276-.9432-.357-1.9325-.24-2.9081.117-.8749.349-1.7305.691-2.5446.326-.7838.72-1.5379,1.177-2.2538.456-.6979.87-1.3668,1.244-2.0065.344-.572.627-1.1788.843-1.8103.185-.547.185-1.1398,0-1.6867-.155-.4839-.425-.9227-.787-1.2787s-.806-.6183-1.292-.7643c-1.205-.3649-2.498-.3163-3.672.1382-2.229.7367-3.502,2.3967-3.817,4.9801-.018.2721-.087.5385-.203.7852-.073.1165-.168.2171-.281.2958-.112.0788-.239.1339-.374.1622l-3.184,1.0469c-.224.079-.469.0659-.683-.0363-.113-.052-.212-.1276-.293-.2217-.08-.0942-.139-.2045-.173-.3236-.282-1.0096-.312-2.0732-.087-3.0971.24-1.175.683-2.2993,1.309-3.3225.692-1.1191,1.558-2.1205,2.566-2.9663c1.073-.914,2.316-1.6081,3.657-2.0429v0Zm8.972,21.8761c.283-.0853.589-.0573.852.0784.263.1356.463.3682.558.6487l1.149,3.4752c.047.1388.065.2858.054.432s-.052.2886-.12.4186c-.064.133-.155.2517-.266.3491s-.241.1715-.381.218l-3.388,1.1196c-.284.0875-.592.0605-.856-.0753-.265-.1358-.467-.3697-.561-.6518l-1.149-3.4679c-.09-.2844-.064-.5926.072-.858.137-.2653.372-.4664.655-.5597l3.381-1.1269Z"
        transform="translate(-206.671829,-32.609334)"
        fill="#fb5f66"
        fillOpacity="0"
      />
    </g>
    <g id="eC0FNawiDyj76_to" transform="translate(238.25821,81.830421)">
      <path
        id="eC0FNawiDyj76"
        d="M228.131,50.754c.787-.4118,1.629-.7104,2.501-.887.827-.1744,1.678-.2015,2.515-.0799.802.1238,1.568.4189,2.247.8651.72.4787,1.301,1.138,1.686,1.9121.332.5971.539,1.2548.611,1.9339.051.5907.017,1.1857-.102,1.7667-.119.574-.289,1.1361-.509,1.6794l-.61,1.5195c-.183.457-.327.9289-.429,1.4104-.095.4279-.068.874.08,1.2869.058.1969.058.4064,0,.6034-.021.0955-.064.185-.124.2618s-.137.1389-.225.1817l-1.927,1.0105c-.186.0956-.402.1164-.603.0582-.1-.023-.193-.0701-.27-.1373-.078-.0672-.137-.1524-.174-.248-.297-.611-.466-1.2768-.494-1.9557-.018-.6088.043-1.2173.181-1.8103.134-.5705.319-1.1277.553-1.6649.225-.5307.429-1.0324.618-1.5122.169-.4271.293-.8706.371-1.3232.062-.3949-.001-.7994-.182-1.156-.162-.3112-.396-.5787-.684-.78-.287-.2012-.619-.3302-.966-.376-.863-.112-1.738.0672-2.487.509-.691.3503-1.258.9056-1.623,1.59-.364.6845-.508,1.4647-.412,2.2341.019.1882.002.3783-.051.5598-.076.1749-.217.3133-.393.3854l-2.057,1.0832c-.145.0785-.315.0968-.473.0509-.082-.0235-.158-.0642-.223-.1194-.065-.0551-.118-.1235-.155-.2005-.304-.6571-.444-1.3781-.407-2.1011.029-.8263.204-1.641.516-2.4064.342-.8418.819-1.6226,1.41-2.312.636-.7551,1.414-1.3777,2.291-1.8321v0Zm8.586,13.8862c.09-.0503.19-.0808.292-.0896.103-.0088.207.0044.304.0387.097.0281.188.0763.265.1414.078.0651.141.1457.186.2367l1.177,2.2319c.093.1822.112.3935.051.5889-.062.1959-.197.3602-.378.4581l-2.181,1.1414c-.089.0492-.187.0792-.289.088-.101.0087-.203-.0039-.3-.0371-.098-.0264-.189-.0731-.268-.1369-.079-.0639-.143-.1436-.19-.2339l-1.17-2.2392c-.093-.1808-.112-.3908-.052-.5851s.193-.3575.372-.4546l2.181-1.1487Z"
        transform="translate(-231.467705,-59.595398)"
        fill="#c94c52"
        fillOpacity="0"
      />
    </g>
    <g id="eC0FNawiDyj77_to" transform="translate(254.201745,71.012122)">
      <path
        id="eC0FNawiDyj77"
        d="M244.346,40.7065c.116.0334.223.0899.315.1662.093.0763.169.1706.223.2773.063.1094.101.2306.114.3558.012.1252-.002.2517-.041.3712l-.872,2.8573c-.076.2305-.237.423-.452.5369-.214.1138-.464.1403-.697.0738l-2.792-.8507c-.115-.0349-.222-.0929-.315-.1704-.092-.0774-.168-.1728-.223-.2803-.064-.1087-.103-.23-.116-.3556-.012-.1255.002-.2522.044-.3714l.872-2.8573c.035-.1154.093-.2226.17-.315.078-.0925.173-.1683.281-.223.109-.0621.23-.1007.356-.1133.125-.0125.251.0014.371.0406l2.762.8579Zm4.363-18.8517c1.006.3059,1.963.7539,2.842,1.3304.843.5429,1.582,1.2324,2.181,2.0357.572.7721.974,1.6567,1.178,2.5955.201,1.004.141,2.0429-.174,3.0171-.221.7761-.594,1.5003-1.098,2.1302-.451.5429-.977,1.018-1.563,1.4104-.575.3756-1.181.7015-1.811.9743l-1.803.7851c-.534.2262-1.045.5038-1.526.8289-.44.2785-.791.6771-1.011,1.1487-.108.216-.274.3974-.48.5234-.095.0663-.205.1088-.32.124s-.232.0026-.341-.0367l-2.472-.7271c-.237-.0716-.435-.2336-.553-.4507-.069-.107-.11-.2294-.12-.3562-.01-.1269.011-.2543.062-.3709.263-.7602.676-1.4598,1.214-2.0574.492-.5294,1.052-.9914,1.665-1.3741.587-.3723,1.208-.6887,1.854-.9451.633-.2545,1.221-.509,1.774-.7271.499-.2204.974-.4908,1.418-.807.384-.2779.667-.6735.807-1.1269.124-.3952.15-.8146.076-1.2223-.073-.4076-.244-.7915-.498-1.1187-.631-.8137-1.517-1.3922-2.516-1.6431-.874-.2819-1.815-.2873-2.693-.0154s-1.651.8079-2.214,1.5349c-.141.1728-.311.3203-.502.4362-.212.0846-.449.0846-.661,0l-2.632-.7997c-.185-.0597-.342-.1835-.443-.349-.05-.0891-.08-.1876-.088-.289-.009-.1014.003-.2036.037-.2999.29-.8077.774-1.5318,1.41-2.1083.714-.6731,1.541-1.2145,2.443-1.5995.989-.4221,2.04-.6823,3.112-.7706c1.158-.1196,2.329-.0109,3.446.3199v0Z"
        transform="translate(-246.642273,-33.434362)"
        fill="#fdbfc2"
        fillOpacity="0"
      />
    </g>
    <path
      d="M204.867,213.993c.193,1.313.083,2.653-.32,3.918-.771,2.051-2.326,2.959-3.25,3.759-1.035.922-1.942,1.978-2.697,3.141-.746,1.276-1.699,2.418-2.821,3.38-.872.728-3.286.727-4.442.233s-1.541-.96-1.454-2.821s5.765-2.384,5.765-2.384l9.219-9.226Z"
      fill="#455a64"
    />
    <path
      d="M190.748,220.071c-.488.174-.626,1.09-.727,2.908-.088,1.381-.727,3.388,1.177,4.297c1.52.727,3.977.319,5.046-.967c1.323-1.593,2.225-3.003,4.108-4.951c1.635-1.687,3.315-2.232,4.093-5.649.501-2.181.16-3.127-1.309-3.788-2.028-.909-5.293,1.868-6.907,2.283-1.388.356-4.958,5.685-5.481,5.867Z"
      fill="#fb5f66"
    />
    <path
      d="M190.748,220.071c-.488.174-.626,1.09-.727,2.908-.088,1.381-.727,3.388,1.177,4.297c1.52.727,3.977.319,5.046-.967c1.323-1.593,2.225-3.003,4.108-4.951c1.635-1.687,3.315-2.232,4.093-5.649.501-2.181.16-3.127-1.309-3.788-2.028-.909-5.293,1.868-6.907,2.283-1.388.356-4.958,5.685-5.481,5.867Z"
      opacity="0.2"
    />
    <path
      d="M196.22,214.196l.276-.087c.926.07,1.817.383,2.583.907.766.525,1.38,1.243,1.779,2.081.262.611-.589,1.061-1.076.582-.429-.815-1.051-1.511-1.811-2.03-.761-.518-1.636-.842-2.551-.944.23-.222.502-.395.8-.509v0Z"
      fill="#fafafa"
    />
    <path
      d="M194.904,215.214c.868.116,1.694.447,2.401.964.708.517,1.274,1.203,1.648,1.995.262.604-.589,1.055-1.076.582-.359-.711-.871-1.333-1.5-1.822-.628-.489-1.358-.833-2.135-1.006.204-.218.429-.502.662-.713Z"
      fill="#fafafa"
    />
    <path
      d="M229.976,242.725c.283,1.556-.299,2.348-2.312,3.46-2.014,1.113-5.162,1.454-8.434.422s-4.82-2.494-6.18-2.864c-1.359-.371-4.747-.466-6.092-.887-1.345-.422-1.309-1.12-1.272-2.749l24.29,2.618Z"
      fill="#455a64"
    />
    <path
      d="M207.167,232.299c-.416,1.193-.77,2.407-1.062,3.635-.305,1.353-.727,3.636-.291,4.632.437.996,2.29,1.723,5.162,1.759c1.854,0,4.726,1.345,6.682,2.486c1.955,1.142,4.245,1.236,6.412,1.026c2.559-.255,5.235-1.68,5.663-2.727.429-1.047.56-1.839-5.401-4.522-.058,0-6.929-2.712-9.772-5.983l-7.393-.306Z"
      fill="#fb5f66"
    />
    <g opacity="0.2">
      <path d="M207.169,232.299c-.416,1.193-.77,2.407-1.062,3.635-.305,1.353-.727,3.636-.291,4.632.437.996,2.29,1.723,5.162,1.759c1.854,0,4.726,1.345,6.682,2.486c1.955,1.142,4.245,1.236,6.412,1.026c2.559-.255,5.234-1.68,5.663-2.727s.56-1.839-5.401-4.522c-.059,0-6.929-2.712-9.772-5.983l-7.393-.306Z" />
    </g>
    <path
      d="M218.477,245.218c0,0,.618-5.154,4.842-6.543c3.824-1.258,7.073,1.614,6.659,4.05-.283,1.643-4.973,5.278-11.501,2.493Z"
      fill="#fafafa"
    />
    <path
      d="M222.275,237.687c-1.077.073-2.099.499-2.908,1.214-1.229.989-1.28,1.665-1.28,1.665-.093.099-.216.165-.35.189-.135.024-.273.004-.395-.058-.122-.061-.22-.16-.281-.282s-.081-.26-.057-.395c0,0,.335-2.595,3.773-3.06.295-.054.599-.012.868.119.269.13.49.343.63.608v0Z"
      fill="#fafafa"
    />
    <path
      d="M219.896,236.487c-1.071-.014-2.109.375-2.908,1.09-1.228.996-1.25,1.796-1.25,1.796-.094.099-.217.166-.351.189-.134.024-.273.004-.394-.057-.122-.062-.221-.161-.282-.283s-.081-.26-.056-.394c0,0,.327-2.603,3.766-3.061.29-.051.589-.009.854.12.266.129.483.339.621.6v0Z"
      fill="#fafafa"
    />
    <path
      d="M217.542,235.084c-1.07-.089-2.134.228-2.981.887-1.294.908-1.374,1.701-1.374,1.701-.102.089-.228.143-.362.156-.135.012-.269-.018-.385-.086-.116-.069-.208-.172-.262-.296-.054-.123-.067-.261-.038-.392c0,0,.516-2.567,3.977-2.799.294-.031.591.032.848.181.256.149.458.376.577.648v0Z"
      fill="#fafafa"
    />
    <path
      d="M190.805,217.112l-.073,3.504c.83.554,1.796.87,2.792.917.997.046,1.987-.181,2.865-.655.101-.211.458-3.635.458-3.635l-6.042-.131Z"
      fill="#ffa8a7"
    />
    <path
      d="M207.217,229.878c0,0,0,1.658-.059,2.61-.058.953,1.069,2.385,3.977,2.341c2.036,0,3.475-.552,3.425-2.231l.05-2.421-7.393-.299Z"
      fill="#ffa8a7"
    />
    <path
      d="M187.634,187.354c.342-7.386,1.927-10.592,1.927-10.592s-.385-9.139-1.389-20.357c-1.526-17.049-.174-19.572,3.33-27.155c0,0,28.26-3.082,28.892,8.834.793,14.838.32,44.974-.182,50.165-.501,5.191-.392.392-1.875,10.963-1.178,8.354-3.708,30.935-3.708,30.935-1.162.562-2.445.829-3.734.777-1.29-.052-2.547-.421-3.66-1.075c0,0-1.789-27.729-1.418-33.763.444-7.365.902-9.233.902-9.233s-2.908-23.941-3.308-27.794c0,0-.604,6.87-1.105,12.097-.502,5.228-.553,9.452-1.869,20.241-1.032,8.477-3.497,26.761-3.497,26.761-1.013.391-2.102.542-3.183.441s-2.124-.451-3.047-1.022c0,0-3.359-24.283-3.076-30.223Z"
      fill="#455a64"
    />
    <path
      d="M203.446,218.224c0,0-.073-4.093-5.591-4.776l-.32.174c.636-.999,1.621-1.727,2.763-2.043c1.847-.458,3.286.044,4.224,1.309s-.102,4.558-1.076,5.336Z"
      fill="#fafafa"
    />
    <path
      d="M203.394,159.088l-.363-4.893c-3.077-1.09-6.007-2.555-8.724-4.362c1.56,2.627,4.086,4.542,7.037,5.336l.88,4.806.073,11.232l1.097-12.119Z"
      fill="#37474f"
    />
    <path
      d="M193.246,63.0767c-1.548,1.9338-12.999,20.3567-14.671,23.8246s-2.08,5.6926.567,8.4844c3.977,4.1949,14.002,14.3153,14.002,14.3153l5.613-13.392-10.411-6.5432L198.524,68.224l-5.278-5.1473Z"
      fill="#ffa8a7"
    />
    <path d="M188.36,89.773c0,0-2.85-1.0033-5.227.538c0,0,1.876-2.6464,5.758-1.6503l-.531,1.1123Z" fill="#f28f8f" />
    <path d="M199.025,95.255l-9.211-4.5948c-3.396-.4871-7.816,4.8201-7.692,8.8915l8.077,7.1253l8.826-11.422Z" fill="#455a64" />
    <path
      d="M200.361,88.4282l-.581,6.4051c-1.454.4508-1.185,2.4792-.335,3.7297l-.167,1.883l9.451.851l1.418-10.8113-9.786-2.0575Z"
      fill="#ffa8a7"
    />
    <path
      d="M189.993,99.4861c1.047-2.8427,5.177-5.031,7.685-4.8347l2.101.1818c-.581,1.1632.262,1.992,1.789,2.4355c1.963.5671,5.576.887,7.706-.3708l.066-.5453c0,0,4.02,1.1487,5.205,1.5922c3.33,1.2505,4.428,1.8976,7.874,10.8472c1.454,3.831.567,7.801-3.403,9.968-.298,2.37-.632,6.099-.632,6.099c1.095,5.081,1.899,10.219,2.406,15.391c0,0-2.094,4.748-16.598,3.948-11.232-.618-16.46-6.303-16.46-6.303.277-2.756,2.022-7.154,5.599-15.457-4.246-11.937-4.893-18.692-3.338-22.9519Z"
      fill="#37474f"
    />
    <path
      d="M249.965,93.5684h-.037c-.508-.2605-.909-.6909-1.133-1.2164s-.256-1.1127-.092-1.6598.515-1.0193.991-1.3346s1.048-.4538,1.616-.3913c.013.0009.025.0045.037.0105.012.0061.022.0145.03.0248s.014.0221.018.0348c.003.0127.004.026.002.039-.001.0121-.004.024-.01.0348s-.014.0202-.023.0278c-.01.0075-.021.0129-.033.0159s-.024.0035-.036.0014c-.521-.0524-1.044.078-1.479.369s-.755.7242-.905,1.2255c-.151.5013-.122,1.0394.081,1.5218s.567.8793,1.031,1.1223c.011.0061.021.0143.029.0242.007.0099.013.0213.017.0335.003.0122.004.025.002.0376-.001.0125-.005.0246-.012.0356-.009.0164-.023.0296-.04.0374s-.036.01-.054.0062v0Z"
      fill="#e0e0e0"
    />
    <path
      d="M248.477,91.5908l.552.6834c.21.5193.569.9647,1.032,1.2795l.546.6762.414-.3272c.126-.0981.219-.2315.268-.3831.05-.1515.052-.3143.008-.4675-.086-.4078-.267-.7894-.529-1.114-.261-.3246-.596-.5831-.976-.7545-.141-.0747-.302-.1056-.461-.0888s-.31.0807-.433.1834v0l-.421.3126Z"
      fill="#455a64"
    />
    <path
      d="M249.759,94.2952c.727.2763,1.2-.1163,1.083-.8724-.089-.4067-.272-.7869-.535-1.11s-.597-.5805-.977-.7512c-.727-.2762-1.192.1164-1.076.8725.088.4064.269.7866.531,1.1099.261.3233.595.5806.974.7512v0Z"
      fill="#263238"
    />
    <path
      d="M247.926,92.6667l1.519,1.883.647-.5235c.089-.0719.154-.1679.189-.2762.035-.1084.037-.2246.008-.3345-.067-.2852-.199-.5512-.386-.7769-.186-.2258-.423-.4053-.69-.5244-.101-.0533-.215-.0762-.329-.0659-.113.0103-.222.0534-.311.124v0l-.647.4944Z"
      fill="#37474f"
    />
    <path
      d="M233.922,99.3337l.727,2.3333c.371,1.142,1.498,2.051,3.286,2.443c3.635.793,8.535-.763,11.022-3.482c1.236-1.3524,1.636-2.7411,1.287-3.8898l-.727-2.3337-.924.4653c-.512-.2717-1.061-.4699-1.628-.5889-3.635-.7925-8.536.7634-11.022,3.4824-.392.4229-.725.8975-.989,1.4105l-1.032.1599Z"
      fill="#fb5f66"
    />
    <path
      d="M233.922,99.3337l.727,2.3333c.371,1.142,1.498,2.051,3.286,2.443c3.635.793,8.535-.763,11.022-3.482c1.236-1.3524,1.636-2.7411,1.287-3.8898l-.727-2.3337-.924.4653c-.512-.2717-1.061-.4699-1.628-.5889-3.635-.7925-8.536.7634-11.022,3.4824-.392.4229-.725.8975-.989,1.4105l-1.032.1599Z"
      opacity="0.3"
    />
    <path
      d="M250.799,93.83c-.304-.0299-.6-.1162-.873-.2545-.011-.006-.021-.0143-.029-.0242-.007-.0099-.013-.0213-.017-.0335-.003-.0122-.004-.025-.002-.0375.001-.0126.006-.0247.012-.0356.005-.0118.013-.0223.023-.0309.009-.0087.021-.0152.033-.0193s.025-.0056.038-.0045.025.0049.037.011c.248.1308.52.2122.8.24.579.0359,1.151-.1534,1.594-.5285.444-.375.725-.9069.786-1.4845.061-.5777-.103-1.1565-.459-1.6159-.355-.4594-.874-.7637-1.449-.8496-.013.0002-.025-.0025-.037-.0077s-.022-.0131-.03-.0228c-.009-.0097-.015-.0211-.018-.0334-.004-.0124-.004-.0253-.002-.0379.001-.0124.004-.0246.01-.0357.005-.0111.013-.021.023-.0291.009-.0081.02-.0143.032-.0181.012-.0039.024-.0053.037-.0044.326.0238.645.1129.936.262.291.1492.55.3554.76.6064s.368.5418.463.8548c.096.3131.127.6423.093.9678s-.133.641-.291.9275c-.159.2865-.373.5382-.631.7402-.257.202-.553.3502-.869.4357s-.646.1067-.97.0622v0Z"
      fill="#e0e0e0"
    />
    <path
      d="M246.218,91.925c-3.599-.7997-8.535.727-11.029,3.4825-2.493,2.7554-1.585,5.5685,2.014,6.3685s8.535-.77,11.029-3.4895c2.494-2.7191,1.585-5.569-2.014-6.3615Z"
      fill="#fb5f66"
    />
    <path
      d="M246.218,91.925c-3.599-.7997-8.535.727-11.029,3.4825-2.493,2.7554-1.585,5.5685,2.014,6.3685s8.535-.77,11.029-3.4895c2.494-2.7191,1.585-5.569-2.014-6.3615Z"
      opacity="0.2"
    />
    <path
      d="M236.341,96.4764c1.246-1.2031,2.754-2.1011,4.405-2.6233c1.651-.5223,3.401-.6546,5.112-.3866.629.1137,1.219.3837,1.716.7852.174.1515.306.3458.383.5638.076.218.094.4521.053.6794-.161.6511-.495,1.2465-.967,1.7231-1.246,1.2031-2.753,2.101-4.405,2.6233-1.651.5227-3.401.6547-5.112.3867-.627-.108-1.215-.3762-1.708-.7781l-.073-.0654c-.945-.9888.102-2.3556.596-2.9081Z"
      fill="#fb5f66"
    />
    <path
      d="M236.341,96.4764c1.246-1.2031,2.754-2.1011,4.405-2.6233c1.651-.5223,3.401-.6546,5.112-.3866.629.1137,1.219.3837,1.716.7852.174.1515.306.3458.383.5638.076.218.094.4521.053.6794-.161.6511-.495,1.2465-.967,1.7231-1.246,1.2031-2.753,2.101-4.405,2.6233-1.651.5227-3.401.6547-5.112.3867-.627-.108-1.215-.3762-1.708-.7781l-.073-.0654c-.945-.9888.102-2.3556.596-2.9081Z"
      opacity="0.5"
    />
    <path
      d="M236.729,97.6542c1.247-1.2013,2.754-2.098,4.405-2.6201s3.4-.6555,5.111-.3898c.629.1137,1.219.3837,1.716.7852l.066.0654c-.161.6511-.495,1.2465-.967,1.7231-1.246,1.2031-2.754,2.1011-4.405,2.6233-1.651.5227-3.401.6547-5.112.3867-.627-.108-1.215-.3762-1.709-.7781l-.072-.0654c.159-.6538.493-1.252.967-1.7303v0Z"
      fill="#f0f0f0"
    />
    <path d="M240.836,97.4434l-2.101,1.5703c-.167.1236,0,.2472.255.1527l2.799-1.1487-.953-.5743Z" fill="#455a64" />
    <path d="M242.532,97.6031l2.102-1.5703c.167-.1236,0-.2472-.255-.1527l-2.799,1.1487.952.5743Z" fill="#455a64" />
    <path
      d="M242.551,98.0397c-.307.18-.653.2862-1.008.3102-.356.024-.713-.0349-1.042-.1721-.472-.2835-.334-.8142.313-1.1705.308-.18.653-.2862,1.009-.3102.355-.024.712.0349,1.041.1721.473.2835.334.8142-.313,1.1705Z"
      fill="#fb5f66"
    />
    <path
      d="M242.243,97.8575c-.197.1173-.419.1863-.648.2014-.228.0152-.457-.0239-.668-.1141-.313-.1818-.218-.5235.204-.727.197-.1173.418-.1863.647-.2014.229-.0152.458.0239.669.1141.312.1527.218.4944-.204.727Z"
      fill="#37474f"
    />
    <path
      d="M250.883,96.0331c-.333-.5517-.779-1.0273-1.308-1.3959l.603,1.9194c.473,1.4177-.56,3.2788-1.585,4.3624-.69.727-1.985,1.563-2.094,1.301-.145-.371.073-2.181-.298-3.8241-.45-1.9629-1.614-1.0105-1.919.4653-.167.807-1.04,2.8648-1.287,4.4278-.305,1.912.087,3.548-.487,4.929-.443,1.083-5.089,6.914-7.808,10.04-2.792-3.722-6.543-8.571-9.822-12.737-4.573-5.86-5.511-7.0887-9.175-7.205-2.909,8.39,2.072,16.838,2.072,16.838s6.877,8.353,11.159,12.745c4.13,4.238,5.584,3.875,7.569,2.181s7.11-9.292,9.291-13.501c2.181-4.21,4.515-7.394,5.816-9.626c2.181-3.751,1.549-7.6919-.727-10.9199Z"
      fill="#ffa8a7"
    />
    <path
      d="M214.534,97.9304c0,0,3.432-.4871,5.751,1.3668c1.457,1.3208,2.779,2.7818,3.948,4.3618c3.206,4.29,9.182,12.258,9.182,12.258-.986,1.781-2.323,3.343-3.93,4.593s-3.45,2.16-5.419,2.677c0,0-5.875-6.143-8.07-9.189-3.883-5.373-3.483-13.065-1.462-16.0676Z"
      fill="#455a64"
    />
    <path
      d="M199.779,94.8334c0,0-4.696,2.0793-5.641,5.0892l-4.413-.5525c1.262-2.1369,3.252-3.7474,5.605-4.5367c1.454-.4146,2.995-.4146,4.449,0v0Z"
      fill="#fb5f66"
    />
    <path
      d="M199.779,94.8334c0,0-4.696,2.0793-5.641,5.0892l-4.413-.5525c1.262-2.1369,3.252-3.7474,5.605-4.5367c1.454-.4146,2.995-.4146,4.449,0v0Z"
      opacity="0.2"
    />
    <path
      d="M209.98,104.219c0,0,1.832-5.5616,4.551-6.2887-1.489-.9034-3.182-1.4161-4.922-1.4904-1.827,1.3279-3.28,3.1062-4.216,5.1621l4.587,2.617Z"
      fill="#fb5f66"
    />
    <path
      d="M209.98,104.219c0,0,1.832-5.5616,4.551-6.2887-1.489-.9034-3.182-1.4161-4.922-1.4904-1.827,1.3279-3.28,3.1062-4.216,5.1621l4.587,2.617Z"
      opacity="0.2"
    />
    <path
      d="M192.349,97.9305c-2.617-.0364-7.772,3.2785-8.688,5.3215s-4.529,29.009-2.282,31.713c2.813,3.388,15.74,11.065,21.81,10.847c4.988-.174,9.888-3.955,10.644-5.292c1.236-2.181-.436-31.989-2.028-34.527-2.603-4.042-16.111-8.0044-19.456-8.0625Z"
      fill="#fb5f66"
    />
    <path
      d="M208.959,103.521l-2.589,1.745c-2.61,1.505-4.02,5.467-3.976,14.955.043,9.487-.139,22.341,1.381,23.701c1.519,1.359,4.58.429,4.762.378c2.726-1.163,4.791-2.908,5.278-3.766c1.236-2.181-.436-31.989-2.028-34.527-.756-1.02-1.72-1.868-2.828-2.486v0Z"
      opacity="0.2"
    />
    <path
      d="M212.925,141.552c-1.781,1.658-5.744,4.137-9.757,4.282-6.041.211-18.975-7.466-21.81-10.854-2.24-2.698,1.323-29.568,2.282-31.713.96-2.145,6.071-5.3511,8.688-5.322c2.618.0291,11.894,2.661,16.606,5.576l-2.589,1.745c-2.602,1.505-4.02,5.467-3.969,14.962s-.146,22.342,1.374,23.694c1.519,1.352,4.58.429,4.762.378c1.611-.669,3.102-1.597,4.413-2.748v0Z"
      opacity="0.1"
    />
    <path
      d="M192.08,97.9302c-2.908,0-7.226,2.3408-8.688,4.6098-1.461,2.268-2.508,8.862-1.454,10.803s8.208,5.816,13.087,7.779c3.889,1.592,5.925,1.178,7.27.197.872-.626,1.025-2.487,1.09-3.868.175-3.548-.116-5.446,2.022-7.532c2.137-2.087,5.954-3.839,6.601-2.268c0,0,.865-2.363-3.562-4.646-7.293-3.7662-12.818-5.0748-16.366-5.0748Z"
      fill="#fb5f66"
    />
    <path
      d="M196.206,116.629l2.573.975c0,0-.341,7.83-.327,10.905c0,0-.603,1.316-1.163,1.534s-1.294-1.258-1.411-2.37c-.116-1.112.059-8.39.328-11.044Z"
      fill="#455a64"
    />
    <path
      d="M196.13,117.24l2.617.902c.068.029.141.041.214.034s.144-.031.205-.071c.061-.041.111-.096.146-.16.035-.065.053-.137.053-.21l.102-1.258c.001-.142-.04-.281-.118-.4-.077-.118-.188-.212-.318-.269l-2.625-.945c-.065-.029-.137-.041-.209-.035s-.141.029-.201.068-.11.092-.145.155c-.035.062-.054.133-.055.205l-.095,1.323c0,.14.041.277.117.394s.185.21.312.267Z"
      fill="#37474f"
    />
    <path
      d="M197.12,126.364c.21.097.389.25.517.443.129.192.201.417.21.648.013.092.001.185-.035.27-.036.086-.094.16-.169.214-.075.055-.163.088-.255.096s-.185-.009-.268-.049c-.212-.096-.392-.249-.52-.441-.129-.193-.201-.418-.207-.65.014-.458.334-.691.727-.531Z"
      fill="#e0e0e0"
    />
    <path
      d="M185.452,111.017l2.515,1.112c0,0-.727,7.801-.887,10.862c0,0-.669,1.287-1.236,1.454s-1.228-1.323-1.294-2.435c-.065-1.113.502-8.361.902-10.993Z"
      fill="#455a64"
    />
    <path
      d="M185.357,111.613l2.56,1.04c.065.032.138.048.211.046.073-.003.145-.023.208-.06s.117-.088.155-.151c.039-.062.061-.133.065-.206l.168-1.258c.007-.139-.026-.278-.096-.399-.07-.122-.173-.22-.297-.284l-2.574-1.084c-.064-.032-.136-.048-.208-.046-.073.002-.143.021-.206.057-.063.037-.116.088-.154.149-.038.062-.06.132-.064.204l-.167,1.316c-.012.14.02.281.091.402.072.121.18.217.308.274Z"
      fill="#37474f"
    />
    <path
      d="M185.875,120.781c.202.11.371.273.487.471.117.199.177.425.174.656.007.088-.011.177-.049.257-.039.08-.099.149-.172.199-.074.05-.159.08-.248.086-.088.007-.177-.01-.258-.048-.204-.107-.374-.269-.49-.469-.115-.199-.172-.427-.164-.658c0-.458.335-.676.72-.494Z"
      fill="#e0e0e0"
    />
    <path
      d="M182.691,125.95c0,3.133-.087,4.427.051,5.932.019.618.196,1.22.512,1.751.317.53.764.971,1.298,1.281c1.52,1.076,8.07,4.522,10.004,5.445c2.37,1.12,4.951,1.869,4.915,0c0-1.606,0-5.023,0-6.194c0-1.454-.727-2.377-3.635-3.737-2.785-1.287-8.986-4.776-10.702-5.554-1.454-.633-2.435.138-2.443,1.076Z"
      fill="#fb5f66"
    />
    <path
      d="M216.235,75.9814c0,0,4.951-6.3833,1.272-9.844-4.362-4.0931-10.142-4.0059-17.696-1.374-3.533,1.2359-8.673,4.8201-9.131,11.756-.349,5.3582,14.584,9.3059,14.242,12.9701l11.313-13.5081Z"
      fill="#263238"
    />
    <path d="M217.51,78.7661c0,0,2.021,3.2425,1.737,3.577-.503.3115-1.056.533-1.635.6543l-.102-4.2313Z" fill="#f28f8f" />
    <path
      d="M211.327,68.4715c2.021,1.1196,5.518,3.1771,6.223,10.76.727,7.7864-1.069,10.44-2.181,11.4288-.727.6616-4.421.8143-6.325.1672-2.392-.8143-7.532-3.5261-9.866-7.5974-2.748-4.7911-3.17-11.0944.211-13.4645c4.784-3.3371,10.222-2.232,11.938-1.2941Z"
      fill="#ffa8a7"
    />
    <path d="M210.039,91.0674c1.642.2971,3.328.2351,4.944-.1818-1.723.006-3.413-.4781-4.871-1.3959l-.073,1.5777Z" fill="#f28f8f" />
    <path
      d="M211.225,77.6389c1.311-.4916,2.463-1.3304,3.334-2.4266.871-1.0963,1.427-2.4086,1.61-3.7967.763-4.9365-5.409-7.0303-5.409-7.0303l.465,13.2536Z"
      fill="#263238"
    />
    <path
      d="M210.572,84.4294c-.407.647-2,1.2577-3.679-.4871-1.679-1.7449-1.621-4.9147.793-5.649c2.413-.7343,3.569,2.0648,3.569,2.0648c2.269-4.4131.669-11.5452.669-11.5452s-5.264-5.4164-13.813-2.8281c-4.588,1.3886-6.943,6.1797-7.452,9.7494s-1.883,9.9457-.102,12.2576c1.781,2.312,8.659,5.8889,14.838,5.5909c6.18-.2981,6.195-1.5195,6.195-1.5195l-.945-7.801c0,0-.015.0654-.073.1672Z"
      fill="#263238"
    />
    <path
      d="M193.249,63.0768c2.399-2.9081,6.201-2.232,9.16-1.7449s7.27.9015,6.543,2.9081-2.908,3.8024-7.997,3.308-6.027,0-7.168,1.345c-.029-.0437-3.359-1.3814-.538-5.8162Z"
      fill="#ffa8a7"
    />
  </svg>
);

export default Error404_2;
