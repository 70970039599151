import { useEffect, useState } from "react";
import { motion } from "framer-motion";

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  content?: {
    title: string;
    message: string;
  };
  buttons?: {
    confirm: string;
    cancel: string;
  };
}

export default function BoolModal({
  onConfirm,
  onCancel,
  content,
  buttons,
}: Props) {
  const [confirmationLoading, setConfirmationLoading] = useState(false); // this state helps when there is any async operation on confirmation

  if (!content) {
    content = {
      title: "Leave Builder",
      message: "Are you sure you want to leave the builder?",
    };
  }

  if (!buttons) {
    buttons = {
      confirm: "Yes",
      cancel: "No",
    };
  }
  
useEffect(() => {
  const element = document.querySelector(
    ".onboardai-container",
  ) as HTMLDivElement;
  if (element) element.style.zIndex = "100";
  return () => {
    if (element) element.style.zIndex = "unset";
    console.log("🚀 ~ return ~ element:", element)
  };
}, []);

  return (
    <div className="createbot-modal leave-bot-modal get_credits_modal err_modal">
      <motion.div
        initial={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="craetebot-container">
          <div className="success-cont leave-modal">
            <div className="err-header fx-d-col gap-8">
              <ErrorIcon />
              <h1 className="mt-3">{content?.title}</h1>
              <p>{content?.message}</p>
            </div>
            <div className="succ-footer">
              <div className="fx">
                <button
                  className="leave-btn mt-0"
                  onClick={onCancel}
                  disabled={confirmationLoading}
                >
                  {buttons.cancel}
                </button>
                <button
                  className="stay-btn mt-0"
                  onClick={() => {
                    setConfirmationLoading(true);
                    onConfirm();
                  }}
                  disabled={confirmationLoading}
                >
                  {confirmationLoading ? (
                    <div className="load-container">
                      <div className="linespinner ls-white"></div>
                    </div>
                  ) : (
                    buttons.confirm
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

const ErrorIcon = () => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2828_47015)">
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="#FB5F66"
      />
      <path
        d="M24.9998 30C24.0944 30 23.2242 29.6496 22.5714 29.0223C21.9186 28.395 21.5339 27.5394 21.4978 26.6348L21.0359 14.9707C21.0174 14.4992 21.0942 14.0289 21.2619 13.5878C21.4295 13.1467 21.6845 12.7441 22.0115 12.4039C22.3385 12.0638 22.7308 11.7932 23.1649 11.6083C23.599 11.4235 24.066 11.3282 24.5379 11.3281H25.4617C25.9335 11.3282 26.4005 11.4235 26.8346 11.6083C27.2687 11.7932 27.6611 12.0638 27.9881 12.4039C28.3151 12.7441 28.5701 13.1467 28.7377 13.5878C28.9053 14.0289 28.9822 14.4992 28.9636 14.9707L28.5017 26.6348C28.4657 27.5394 28.0809 28.395 27.4281 29.0223C26.7753 29.6496 25.9051 30 24.9998 30Z"
        fill="white"
      />
      <path
        d="M28.498 35.1729C28.498 33.2404 26.9315 31.6738 24.999 31.6738C23.0666 31.6738 21.5 33.2404 21.5 35.1729C21.5 37.1053 23.0666 38.6719 24.999 38.6719C26.9315 38.6719 28.498 37.1053 28.498 35.1729Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2828_47015">
        <rect width={50} height={50} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
