interface Props {
  children: React.ReactNode;
  disable: boolean;
  grayScale?: number;
}

export default function DisableLayout({ children, disable, grayScale }: Props) {
  return (
    <div
      className={
        disable ? "disable-layout disable wd-100" : "disable-layout wd-100"
      }
      onClickCapture={(event: any) => {
        if (!disable) return;
        const { className, baseURI } = event.target;
        if (className === "user_id flex-cn" && baseURI.includes("plan_expired"))
          return;
        if (className === "user-logout") return;
        // console.log(event.target.className);
        event.stopPropagation();
      }}
      style={{
        ...(grayScale && disable && { filter: `grayscale(${grayScale ?? 1})` }),
      }}
    >
      {children}
    </div>
  );
}
