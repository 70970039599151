import environment from "@/constants/Environment";

const environmentData = environment();

export default function Loader() {
  return (
    <div className="pre-loader">
      {environmentData.isAI ? (
        // <CBBLoader />
        <img
          src="https://res.cloudinary.com/dghqyted6/image/upload/v1682495224/ChatbotBuilder_Logo_1_nsqgax.svg"
          height={100}
          width={120}
        />
      ) : (
        <img
          src="https://res.cloudinary.com/dnag1wvx8/image/upload/v1659090825/engage/path-fillNEW_00000_ztmcqd.gif"
          height={140}
          width={245}
        />
      )}
    </div>
  );
}

function CBBLoader() {
  return (
    <svg
      id="ekRLJHZOKTc1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 280 275"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      height={140}
      width={245}
    >
      <style />
      <defs>
        <linearGradient
          id="ekRLJHZOKTc3-stroke"
          x1={51.157}
          y1={17.7787}
          x2={137.165}
          y2={265.524}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="ekRLJHZOKTc3-stroke-0" offset="0%" stopColor="#ff898e" />
          <stop
            id="ekRLJHZOKTc3-stroke-1"
            offset="54.6875%"
            stopColor="#ea0d17"
          />
          <stop id="ekRLJHZOKTc3-stroke-2" offset="100%" stopColor="#fb5f66" />
        </linearGradient>
      </defs>
      <path
        id="ekRLJHZOKTc2"
        d="M226.121,136.818l-.022.335v.335c0,11.928-2.418,19.641-5.864,24.818-3.385,5.084-8.42,8.711-15.603,11.277-7.354,2.626-16.576,3.996-27.682,4.643-9.757.569-20.39.56-31.919.549-1.576-.001-3.169-.003-4.779-.003s-3.203.002-4.779.003c-11.529.011-22.161.02-31.919-.549-11.106-.647-20.3281-2.017-27.6814-4.643-7.1836-2.566-12.2185-6.193-15.6035-11.277-3.4461-5.177-5.8637-12.89-5.8637-24.818c0-47.5389,38.5614-86.7945,85.8466-86.7945c48.169,0,89.208,36.8819,85.869,86.1245Z"
        transform="matrix(-1 0 0 1 280.716866 0)"
        opacity={0}
        fill="none"
        stroke="#444"
        strokeWidth={19.8323}
        strokeLinecap="round"
        strokeDashoffset={493}
        strokeDasharray={493}
      />
      <path
        id="ekRLJHZOKTc3"
        d="M37.9722,63.558c-61.0954,90.109-1.5223,156.404,46.5822,153.403.9756-.061,1.8147.701,1.8147,1.678v42.809c0,1.484,1.8049,2.216,2.8387,1.151L132.55,217.942c.693-.713,1.679-1.076,2.67-.993c59.454,4.969,106.275-5.87,123.581-39.882c21.663-42.573.948-94.8143-19.911-117.5699C195.275,-1.18431,86.3687,-7.8215,37.9722,63.558Z"
        fill="none"
        stroke="url(#ekRLJHZOKTc3-stroke)"
        strokeWidth={23.1377}
        strokeLinecap="round"
        strokeDashoffset={810}
        strokeDasharray={810}
      />
      <g
        id="ekRLJHZOKTc4_ts"
        transform="translate(105.345001,122.315002) scale(0,0)"
      >
        <circle
          r={11.9059}
          transform="translate(-0.000001,-0.000002)"
          fill="#fb5f66"
        />
      </g>
      <g
        id="ekRLJHZOKTc5_ts"
        transform="translate(141.061996,122.315002) scale(0,0)"
      >
        <circle
          r={11.9059}
          transform="translate(0.000004,-0.000002)"
          fill="#fb5f66"
        />
      </g>
      <g
        id="ekRLJHZOKTc6_ts"
        transform="translate(176.779999,122.315002) scale(0,0)"
      >
        <circle
          r={11.9059}
          transform="translate(0.000001,-0.000002)"
          fill="#fb5f66"
        />
      </g>
    </svg>
  );
}
