import { User } from "@/redux/Types";

export const hashPayload = async (
  payload: any,
  userId: User["userId"],
  companyId: User["companyDetails"]["_id"],
) => {
  const string = userId + "`^`" + JSON.stringify(payload) + "`^`" + companyId;
  const encoder = new TextEncoder();
  const data = encoder.encode(string);
  const algoritm = "SHA-256";
  const hashBuffer = await crypto.subtle.digest(algoritm, data);

  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
};
