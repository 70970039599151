import { userSlice } from "@/redux/User";
import { axiosGet } from "@/services/axiosService";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import environment from "../constants/Environment";

export default function useGetPlanDetails() {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ["planDetails"],
    queryFn: async () => {
      const response = await axiosGet(`/payments/planDetails`);

      let plan = environment().isAI
        ? response.data.data.ai_subscription?.plan
        : response.data.data.subscription?.plan;
      plan = {
        ...plan,
        ai_addons: response.data.data?.ai_addons,
        ai_currentUsage: response.data.data?.ai_currentUsage,
        ai_currentLimits: response.data.data?.ai_currentLimits,
        currentLimits: response.data.data?.currentLimits,
        current_usage: response.data.data?.current_usage,
        addons: response.data.data?.omniaddons,
      };

      const billing = environment().isAI
        ? response.data.data.ai_billing?.current_term_ends_at
        : response.data.data.billing?.current_term_ends_at;

      dispatch(userSlice.actions.setPlan(plan));
      dispatch(userSlice.actions.setBilling(billing));

      return { response, plan, billing };
    },
    staleTime: 1000,
  });
}
