import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./InitialState";

export const appsSlice = createSlice({
    name: "apps",
    initialState: initialState.apps,
    reducers: {
      setAppsData: (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      },
  
      clear(state) {
        return initialState.apps;
      },
    },
  });
  
  const appReducer = appsSlice.reducer;
  export default appReducer;