import axios from "axios";
import environment from "../constants/Environment";
import { router } from "@/App";
import { hashPayload } from "@/utils/hashPayload";

const environmentData = environment();
const baseURL = environmentData?.SERVER_API_URL;

export const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(function (response) {
  response.headers["token"] = window.localStorage.getItem("omni-token");

  return response;
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const pathname = router.window?.location.pathname ?? "";
    const disableRoute = ["/login", "/logout"];

    if (
      !disableRoute.includes(pathname) &&
      error.config.url !== "/event/progress"
    ) {
      switch (error.response?.status) {
        case 401: {
          router.navigate("/logout");
          break;
        }
        case 403: {
          const mu = window.localStorage.getItem("mu");
          if (mu === "true") break;

          router.navigate("/logout");
          break;
        }
      }
    }

    return Promise.reject(error);
  },
);

export async function axiosGet(url: string, params?: Object) {
  return axiosInstance.get(url, {
    ...(params && { params }),
  });
}

export async function axiosPost(url: string, data: Object = {}) {
  return axiosInstance.post(url, data);
}

export async function axiosHashedPost(
  url: string,
  payload: any,
  userId: string,
  companyId: string,
  signal?: AbortSignal,
) {
  const requestHash = await hashPayload(payload, userId, companyId);
  const response = await axiosInstance.post(url, payload, {
    headers: {
      "request-hash": requestHash,
    },
    signal,
  });

  const responseHash = await hashPayload(response.data, userId, companyId);
  if (response.headers["response-hash"] !== responseHash) {
    throw new Error("Data checksum mismatch");
  }
  return response;
}

export async function axiosDelete(url: string) {
  return axiosInstance.delete(url);
}

export async function axiosPut(url: string, data?: Object) {
  return axiosInstance.put(url, data ?? {});
}

export async function axiosPatch(url: string, data?: Object) {
  return axiosInstance.patch(url, data ?? {});
}
