import React, { Fragment, useRef, useState, useEffect } from "react";
import SideNav from "../../components/layoutElements/sideNav";
import "./templates.css";
import Template from "../../components/layoutElements/template";
import { axiosGet } from "../../services/axiosService";
import { handleSearch } from "../../utils/handleSearch";
import { useNavigate } from "react-router-dom";
import environment from "../../constants/Environment";
import OmniSideNav from "../../components/layoutElements/OmniSideNav";

type routeType = "/dashboard" | "/integration" | "/analytics" | "/chats" | "/audience" | "/settings";
import { TabTitle } from "../../utils/TitleFuntion";

const Templates = () => {
  const router = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("All Templates");
  const [search, setSearch] = useState<any>("");
  const [premades, setPremades] = useState<any>([]);
  const List = ["All Templates", "Web", "Webhook", "Campaign"];
  const categories = ["Web", "Webhook", "Campaign"];
  const [showAll, setShowAll] = useState<string>("");
  let menuRef = useRef<any>();

  const setRoute = (routeInput: routeType = "/dashboard") => router(routeInput);

  useEffect(() => {
    TabTitle(" | Templates");
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const getPremades = async () => {
    try {
      const res = await axiosGet(`admin/getPremades`);
      setPremades(res.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
    console.log(premades);
  };

  useEffect(() => {
    getPremades();
  }, []);

  return (
    <Fragment>
      <div className="vertical-overlay"></div>
      {/* {environment().isAI ? <SideNav /> : <OmniSideNav setRoute={setRoute} />} */}
      <div className="templates-wrapper">
        <div className="nav_section">
          <div className="inner_section">
            <div className="welcome-user">
              <div className="welcome-head">
                <h1>Welcome tiger</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="cards-tables">
          <div className="fx jstfy-sp-btw al-cnt">
            <div className="dashboard_header no-m templates-header fx fx-d-col gap-5 ">
              <h1>Templates</h1>
              <p>Elit donec nisi mollis platea enim, suspendisse amet ac. Facilisis in.</p>
            </div>
            <div className="templates-drpdwn-search fx gap-20">
              <div className="templates-all flex-cnt p-relative" ref={menuRef} onClick={() => setOpen(!open)}>
                <span className="flex-cnt" style={{ gap: "60px" }}>
                  {selected} <ArrowIcon />
                </span>
                {open && (
                  <ul className="p-absolute ">
                    {List.map((menu) => (
                      <li
                        onClick={(e) => {
                          setSelected(menu);
                          setOpen(false);
                        }}
                      >
                        {menu}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="thin-line"></div>
              <div className="templates-search flex-cnt gap-20">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search Template"
                  className="bd-none no-bg"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="thin-line mt-30"></div>
          {categories.map((category) => {
            if (selected == category || selected == "All Templates") {
              return (
                <>
                  <div className="mt-20 fx al-cnt jstfy-sp-btw">
                    <div className="temp-heading fx al-cnt gap-10">
                      <h1>{category}</h1>{" "}
                      <div className="count-bx">
                        {
                          premades.filter((pre) => {
                            return handleSearch(search, pre.name) && pre.type == category.toLowerCase();
                          }).length
                        }
                      </div>
                    </div>
                    {premades.filter((pre) => {
                      return pre.type == category.toLowerCase();
                    }).length > 5 && (
                      <span
                        className="c-more cp"
                        onClick={() => {
                          setShowAll(showAll == category ? "" : category);
                          setSelected(showAll == category ? "All Templates" : category);
                        }}
                      >
                        {showAll == category ? "See Less" : "See More"}
                      </span>
                    )}
                  </div>
                  <Template
                    premade={premades.filter((pre) => {
                      return handleSearch(search, pre.name) && pre.type == category.toLowerCase();
                    })}
                    showAll={showAll == category}
                  />
                </>
              );
            }
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default Templates;

export function SearchIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <g clipPath="url(#clip0_828_2254)">
        <path
          d="M5.53331 0C6.91244 0 8.23508 0.547855 9.21027 1.52304C10.1855 2.49823 10.7333 3.82087 10.7333 5.2C10.7333 6.488 10.2613 7.672 9.48531 8.584L9.70131 8.8H10.3333L14.3333 12.8L13.1333 14L9.13331 10V9.368L8.91731 9.152C7.97375 9.95742 6.77388 10.3999 5.53331 10.4C4.15419 10.4 2.83155 9.85215 1.85636 8.87696C0.881168 7.90177 0.333313 6.57913 0.333313 5.2C0.333313 3.82087 0.881168 2.49823 1.85636 1.52304C2.83155 0.547855 4.15419 0 5.53331 0ZM5.53331 1.6C3.53331 1.6 1.93331 3.2 1.93331 5.2C1.93331 7.2 3.53331 8.8 5.53331 8.8C7.53331 8.8 9.13331 7.2 9.13331 5.2C9.13331 3.2 7.53331 1.6 5.53331 1.6Z"
          fill="black"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_828_2254">
          <rect width="14" height="14" fill="white" transform="translate(0.333313)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ArrowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path d="M0.499969 0.833496L4.8333 5.16683L9.16664 0.833496H0.499969Z" fill="#444444" />
    </svg>
  );
}
