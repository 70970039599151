import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./InitialState";

export const botSlice = createSlice({
  name: "bot",
  initialState: initialState.bot,
  reducers: {
    setBotData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setLiveLoading: (state, action) => {
      return {
        ...state,
        liveLoading: action.payload,
      };
    },
    setGdprData: (state, action) => {
      return {
        ...state,
        // liveLoading: action.payload,
      };
    },
    setEmbedData: (state, action) => {
      return {
        ...state,
        // liveLoading: action.payload,
      };
    },

    clear(state) {
      return initialState.bot;
    },
  },
});

const botReducer = botSlice.reducer;
export default botReducer;
