import { Link, useNavigate } from "react-router-dom";
import "@/styles/dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import UserSection from "../header/UserSection";
import { AiChatBotShortLogo } from "@/assets/AiBotAssets";
import environment from "@/constants/Environment";
import { userSlice } from "@/redux/User";
import { TabTitle } from "@/utils/TitleFuntion";
import { useEffect, useState } from "react";
import { addValue } from "@/redux/User";

type routeType =
  | "/dashboard"
  | "/integration"
  | "/analytics"
  | "/chats"
  | "/audience"
  | "/settings";

function SideNav() {
  const add_class = useSelector((state: any) => state.user.addClass);
  const role = useSelector((state: any) => state.user.role);
  const plan = useSelector((state: any) => state.user.plan);
  const setting = useSelector((state: any) => state.user.settings);
  const dispatch = useDispatch();
  const router = useNavigate();
  const [inBuilder, setInBuilder] = useState<boolean>(false);

  const setRoute = (routeInput: routeType = "/dashboard") => router(routeInput);

  const addvalue = () => {
    dispatch(addValue(add_class ? "" : "app-collapse"));
    console.log(add_class);
    
  };

  useEffect(() => { 
    if (
      (window.screen.width < 1279 ||
        window.location.pathname.includes("/builder")) &&
      !document.querySelector(".main-app")?.classList.contains("app-collapse")
    ) {
      addvalue();
    }
  }, []);

  useEffect(()=> {
    if (window.location.pathname.includes("/builder")) setInBuilder(true);
    else setInBuilder(false);
    if (
      window.location.pathname.includes("/builder") &&
      !document.querySelector(".main-app")?.classList.contains("app-collapse")
    ) {
      addvalue();
    }
  },[setRoute])

  return (
    <div className="left-col sn">
      <div className="closing-modal">
        <button onClick={addvalue}>
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
              fill="#6D6D6D"
            ></path>
          </svg>
        </button>
      </div>
      <div className="sidebar sn">
      <div
          onClick={addvalue}
          className={`line_icon ${
            window.location.pathname.includes("/builder") ? "none" : ""
          }`}
        >
          <svg
            className="wb-svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.175 2.08337L5 5.94961L8.825 2.08337L10 3.27947L5 8.33337L0 3.27947L1.175 2.08337Z"
              fill="#666666"
            ></path>
          </svg>

          <svg
            className="fn-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.467 19C17.467 18.8011 17.388 18.6103 17.2474 18.4697C17.1067 18.329 16.916 18.25 16.717 18.25H4.00004C3.80113 18.25 3.61036 18.329 3.46971 18.4697C3.32906 18.6103 3.25004 18.8011 3.25004 19C3.25004 19.1989 3.32906 19.3897 3.46971 19.5303C3.61036 19.671 3.80113 19.75 4.00004 19.75H16.717C16.916 19.75 17.1067 19.671 17.2474 19.5303C17.388 19.3897 17.467 19.1989 17.467 19ZM11.778 5C11.778 4.80109 11.699 4.61032 11.5584 4.46967C11.4177 4.32902 11.227 4.25 11.028 4.25H4.00004C3.80113 4.25 3.61036 4.32902 3.46971 4.46967C3.32906 4.61032 3.25004 4.80109 3.25004 5C3.25004 5.19891 3.32906 5.38968 3.46971 5.53033C3.61036 5.67098 3.80113 5.75 4.00004 5.75H11.028C11.227 5.75 11.4177 5.67098 11.5584 5.53033C11.699 5.38968 11.778 5.19891 11.778 5Z"
              fill="#444444"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.75 12C20.75 11.8011 20.671 11.6103 20.5303 11.4697C20.3897 11.329 20.1989 11.25 20 11.25H4C3.80109 11.25 3.61032 11.329 3.46967 11.4697C3.32902 11.6103 3.25 11.8011 3.25 12C3.25 12.1989 3.32902 12.3897 3.46967 12.5303C3.61032 12.671 3.80109 12.75 4 12.75H20C20.1989 12.75 20.3897 12.671 20.5303 12.5303C20.671 12.3897 20.75 12.1989 20.75 12Z"
              fill="#444444"
              fillOpacity="0.5"
            />
          </svg>
        </div>
        <Link to="/dashboard">
          <div
            className="nav-logo flex-cnt"
            style={{ gap: environment().isAI ? "8px" : "2px" }}
          >
            {environment().isAI ? (
              <div className="logo-cbb">
                <AiChatBotShortLogo />
              </div>
            ) : (
              <div className="logo-og">
                <OmniengageLogo />
              </div>
            )}
            {environment().isAI ? (
              <h1 className="nav-logo-chatbotbuilder">ChatBot Builder</h1>
            ) : (
              <h1>Omniengage</h1>
            )}
          </div>
        </Link>
        <div className="links">
          <ul>
            <li
              className={
                window.location.pathname === "/dashboard"
                  ? "home side-active"
                  : "home"
              }
            >
              <Link to="/dashboard">
                <HomeIcon />
                <span>Dashboard</span>
              </Link>
              {!!add_class && (
                <p className="sm-tooltip  flex-cnt">
                  <span className="sm-tooltip_text">Dashboard</span>
                </p>
              )}
            </li>
            <li
              className={
                window.location.pathname === "/integration"
                  ? "integration side-active"
                  : "integration"
              }
            >
              <Link to="/integration">
                <IntegrationIcon />
                <span>Integrations</span>
              </Link>
              {!!add_class && (
                <p className="sm-tooltip  flex-cnt">
                  <span className="sm-tooltip_text">Integrations</span>
                </p>
              )}
            </li>
            {!environment().isAI && (
              <li
                className={
                  window.location.pathname === "/templates"
                    ? "templates side-active"
                    : "templates"
                }
              >
                <Link to="/templates">
                  <TemplatesIcon />
                  <span>Templates</span>
                </Link>
                {!!add_class && (
                  <p className="sm-tooltip  flex-cnt">
                    <span className="sm-tooltip_text">Templates</span>
                  </p>
                )}
              </li>
            )}
            <li
              className={
                window.location.pathname === "/analytics"
                  ? "side-active side-active-analysis"
                  : "analysis"
              }
            >
              <Link to="/analytics">
                <AnalyticsIcon />

                <span>Analytics</span>
              </Link>
              {!!add_class && (
                <p className="sm-tooltip  flex-cnt">
                  <span className="sm-tooltip_text">Analytics</span>
                </p>
              )}
            </li>

            {environment().isAI ? (
              <li
                className={`chats ${window.location.pathname === "/chats" ? "side-active" : ""}`}
              >
                {plan?.name === "Free" ? (
                  <>
                    <Link to={""} className="locked-chat">
                      <ChatsIcon />
                      <span>Chats</span>
                      <div className="lock-icon">
                        <LockSvg />
                      </div>
                    </Link>
                    <div className="new-config-tooltip fx fx-d-col gap-8">
                      <p>
                        Your plan does not include this feature. Buy one of our
                        plans to <span>unlock </span>
                        this feature.
                      </p>
                      <a
                        onClick={() => {
                          router("/settings");
                          dispatch(userSlice.actions.setSettings(2));
                        }}
                      >
                        Upgrade Plan
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <Link to="/chats">
                      <ChatsIcon />
                      <span>Chats</span>
                    </Link>
                    {!!add_class && (
                      <p className="sm-tooltip  flex-cnt">
                        <span className="sm-tooltip_text">Chats</span>
                      </p>
                    )}
                  </>
                )}
              </li>
            ) : (
              <li
                className={
                  window.location.pathname === "/chats"
                    ? `chats side-active`
                    : `chats`
                }
              >
                <Link to="/chats">
                  <ChatsIcon />
                  <span>Chats</span>
                </Link>
                {!!add_class && (
                  <p className="sm-tooltip  flex-cnt">
                    <span className="sm-tooltip_text">Chats</span>
                  </p>
                )}
              </li>
            )}
            {!environment().isAI && (
              <li
                className={
                  window.location.pathname === "/audience"
                    ? "audience side-active"
                    : "audience"
                }
              >
                <Link to="/audience">
                  <AudienceIcon />
                  <span>Audience</span>
                </Link>
                {!!add_class && (
                  <p className="sm-tooltip  flex-cnt">
                    <span className="sm-tooltip_text">Audience</span>
                  </p>
                )}
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="bottom_menu">
        <div className="links">
          {/* <span>Insights</span> */}
          <ul>
            <li
              className={
                window.location.pathname === "/settings" && setting !== 2
                  ? "settings side-active"
                  : "setings"
              }
              onClick={() => {
                setRoute("/settings");
                dispatch(userSlice.actions.setSettings(0));
                TabTitle(`Omniengage | Settings`);
              }}
            >
              <Link to="/settings">
                <SettingsIcon />
                <span>Settings</span>
              </Link>
              {!!add_class && (
                <p className="sm-tooltip flex-cnt">
                  <span className="sm-tooltip_text">Settings</span>
                </p>
              )}
            </li>
            {role === "admin" && (
              <li
                className={
                  (window.location.pathname === "/settings" ||
                    window.location.pathname === "/plan_expired" ||
                    window.location.pathname === "/trial_expired") &&
                  setting === 2
                    ? "pricing side-active pricing-icon"
                    : "pricing pricing-icon"
                }
                onClick={() => {
                  setRoute("/settings");
                  dispatch(userSlice.actions.setSettings(2));
                }}
              >
                <Link to="/settings">
                  <PricingIcon />
                  <span>Pricing</span>
                </Link>
                {!!add_class && (
                  <p className="sm-tooltip flex-cnt">
                    <span className="sm-tooltip_text">Pricing</span>
                  </p>
                )}
              </li>
            )}
          </ul>
        </div>
        <UserSection setRoute={setRoute} />
      </div>
    </div>
  );
}

export default SideNav;

const OmniengageLogo = () => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.3741 30.4054C47.512 29.4679 50.0516 30.4409 50.5457 32.7226C51.2867 36.1448 51.1705 39.7205 50.176 43.1238C48.7834 47.8892 45.7576 52.0123 41.6292 54.7701C37.5008 57.5279 32.5334 58.7443 27.5979 58.206C22.6624 57.6677 18.0739 55.4091 14.6371 51.8262C11.2003 48.2433 9.13443 43.5649 8.80187 38.6113C8.46931 33.6577 9.89124 28.7451 12.8183 24.735C15.7454 20.7248 19.9908 17.8732 24.81 16.68C28.2517 15.8279 31.8292 15.8605 35.2176 16.7432C37.4767 17.3317 38.3433 19.9096 37.3177 22.0067V22.0067C36.292 24.1038 33.7439 24.891 31.4266 24.6081C29.9056 24.4223 28.3519 24.5123 26.8418 24.8862C23.9502 25.6021 21.403 27.3131 19.6468 29.7192C17.8905 32.1253 17.0373 35.0728 17.2369 38.045C17.4364 41.0171 18.6759 43.8242 20.738 45.9739C22.8001 48.1237 25.5532 49.4788 28.5145 49.8018C31.4758 50.1248 34.4563 49.3949 36.9333 47.7403C39.4103 46.0856 41.2258 43.6118 42.0613 40.7525C42.4977 39.2592 42.6523 37.7107 42.5299 36.1832C42.3436 33.8562 43.2361 31.3429 45.3741 30.4054V30.4054Z"
        fill="#FB5F66"
      ></path>
      <path
        d="M53.1185 23.6639C52.3799 24.2422 50.1861 23.4112 47.814 22.3194C45.9231 23.0419 44.7003 22.6322 47.2581 25.3863C49.1221 27.3934 43.7167 27.1529 40.5963 30.2732C36.5793 34.4384 38.3334 43.7882 33.3978 35.9912C31.538 33.0532 34.1797 42.0226 32.9266 42.3874C31.2648 42.6672 31.4684 40.6416 30.8727 35.8983C30.5888 33.6373 30.2527 35.6886 29.8491 38.1711C31.5761 42.301 31.0061 39.5987 29.7744 41.0952L29.4155 40.6893C29.1159 42.237 28.7841 43.3955 28.4158 43.0272C27.5652 42.1765 26.7057 37.7753 25.551 36.3197L25.3955 36.144C25.4479 36.1964 25.4997 36.2552 25.551 36.3197L29.4155 40.6893C29.5682 39.9008 29.7125 39.0113 29.8491 38.1711C29.3228 36.9127 28.5833 35.0199 27.5843 32.1807C26.5209 27.9698 33.7239 30.3348 36.6549 27.996C39.586 25.6572 40.7038 22.6146 40.2445 19.5203C40.3024 18.4608 44.2585 20.6829 47.814 22.3194C48.4192 22.0881 49.0928 21.7408 49.7329 21.1367C50.1888 20.7063 50.5495 20.2429 50.7828 19.7461L46.7626 13.0023C50.7998 15.6984 51.6303 17.9421 50.7828 19.7461L53.1185 23.6639Z"
        fill="#444444"
      ></path>
      <circle
        cx="47.0261"
        cy="20.4576"
        r="7.51467"
        transform="rotate(-135 47.0261 20.4576)"
        fill="#444444"
      ></circle>
      <circle
        cx="30.155"
        cy="37.3282"
        r="9.0176"
        transform="rotate(-135 30.155 37.3282)"
        fill="#444444"
      ></circle>
    </svg>
  );
};

export const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M9.07861 16.1354H14.8936"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.3999 13.713C2.3999 8.082 3.0139 8.475 6.3189 5.41C7.7649 4.246 10.0149 2 11.9579 2C13.8999 2 16.1949 4.235 17.6539 5.41C20.9589 8.475 21.5719 8.082 21.5719 13.713C21.5719 22 19.6129 22 11.9859 22C4.3589 22 2.3999 22 2.3999 13.713Z"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const IntegrationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M17.5946 10.1516C17.5946 9.63563 17.1746 9.20363 16.6586 9.20363H15.8666V6.79163C15.8666 6.27563 15.4466 5.84363 14.9306 5.84363C14.4146 5.84363 13.9946 6.26363 13.9946 6.79163V9.20363H12.1226V6.79163C12.1226 6.27563 11.7026 5.84363 11.1866 5.84363C10.6706 5.84363 10.2506 6.26363 10.2506 6.79163V9.20363H9.41061C8.89461 9.20363 8.47461 9.62363 8.47461 10.1516V12.5876C8.47461 14.7956 10.0226 16.6436 12.0866 17.0876C12.6506 17.1956 13.2746 17.1956 13.9586 17.0876C16.0466 16.6556 17.6186 14.7956 17.6186 12.5756V10.1396L17.5946 10.1516ZM15.7226 12.5876C15.7226 14.0876 14.5106 15.2996 13.0226 15.2996C11.5346 15.2996 10.3226 14.0876 10.3226 12.5876V11.0996H15.7106V12.5876H15.7226Z"
        fill="#444444"
      />
      <path
        d="M21.482 3.54C19.214 1.26 16.202 0 12.998 0C9.79405 0 6.78205 1.26 4.51405 3.54C2.24605 5.82 0.998047 8.904 0.998047 12.132C0.998047 14.928 1.97005 17.628 3.72205 19.788C5.46205 21.912 7.88605 23.388 10.538 23.94C10.73 23.976 10.922 24 11.114 24C11.75 24 12.374 23.784 12.89 23.364C13.55 22.824 13.922 22.02 13.922 21.168V18.48H12.05V21.168C12.05 21.564 11.834 21.792 11.702 21.9C11.486 22.08 11.198 22.152 10.91 22.092C6.24205 21.12 2.85805 16.932 2.85805 12.132C2.87005 6.504 7.41805 1.884 12.998 1.884C18.578 1.884 23.1261 6.456 23.1261 12.072C23.1261 16.188 20.702 19.896 16.946 21.492C16.466 21.696 16.25 22.248 16.454 22.728C16.658 23.208 17.21 23.436 17.678 23.232C19.826 22.32 21.65 20.796 22.958 18.84C24.302 16.836 25.01 14.496 25.01 12.072C25.01 8.844 23.7621 5.808 21.494 3.528L21.482 3.54Z"
        fill="#444444"
      />
    </svg>
  );
};
export const TemplatesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 3.74933 1.03614 1 5.5 1C9.96386 1 10 3.74933 10 12C10 20.2507 10.0142 23 5.5 23C0.985763 23 1 20.2507 1 12Z"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5.5C14 2.12473 14.0361 1 18.5 1C22.9639 1 23 2.12473 23 5.5C23 8.87527 23.0142 10 18.5 10C13.9858 10 14 8.87527 14 5.5Z"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 18.5C14 15.1247 14.0361 14 18.5 14C22.9639 14 23 15.1247 23 18.5C23 21.8753 23.0142 23 18.5 23C13.9858 23 14 21.8753 14 18.5Z"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const AnalyticsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8631 14.0955C18.6126 14.0955 19.2417 14.7141 19.1271 15.4543C18.4548 19.8089 14.7269 23.0421 10.2308 23.0421C5.25648 23.0421 1.22461 19.0102 1.22461 14.037C1.22461 9.93967 4.33738 6.12296 7.84188 5.25999C8.59493 5.07406 9.36669 5.60377 9.36669 6.37904C9.36669 11.6317 9.54326 12.9905 10.5407 13.7295C11.5381 14.4685 12.711 14.0955 17.8631 14.0955Z"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7669 9.72209C22.8265 6.34739 18.6812 0.907644 13.6297 1.00119C13.2368 1.00821 12.9223 1.33562 12.9047 1.72735C12.7773 4.50218 12.9491 8.09789 13.045 9.72794C13.0743 10.2354 13.473 10.6342 13.9793 10.6634C15.655 10.7593 19.3817 10.8903 22.1167 10.4763C22.4886 10.4202 22.761 10.0975 22.7669 9.72209Z"
        stroke="#444444"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChatsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0714 19.0699C16.0152 22.1263 11.4898 22.7867 7.78642 21.074C7.23971 20.8539 6.79148 20.676 6.36537 20.676C5.17849 20.683 3.70117 21.8339 2.93336 21.067C2.16555 20.2991 3.31726 18.8206 3.31726 17.6266C3.31726 17.2004 3.14642 16.7602 2.92632 16.2124C1.21283 12.5096 1.87411 7.98269 4.93026 4.92721C8.8316 1.02443 15.17 1.02443 19.0714 4.9262C22.9797 8.83501 22.9727 15.1681 19.0714 19.0699Z"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M15.9389 12.4121H15.9479"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M11.931 12.4121H11.94"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M7.92128 12.4121H7.93028"
        stroke="#444444"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AudienceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.592 15.207C13.281 15.207 16.434 15.766 16.434 17.999C16.434 20.232 13.302 20.807 9.592 20.807C5.902 20.807 2.75 20.253 2.75 18.019C2.75 15.785 5.881 15.207 9.592 15.207Z"
        stroke="#444444"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.59108 12.02C7.16908 12.02 5.20508 10.057 5.20508 7.635C5.20508 5.213 7.16908 3.25 9.59108 3.25C12.0121 3.25 13.9761 5.213 13.9761 7.635C13.9851 10.048 12.0351 12.011 9.62208 12.02H9.59108Z"
        stroke="#444444"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M16.4844 10.8813C18.0854 10.6563 19.3184 9.28228 19.3214 7.61928C19.3214 5.98028 18.1264 4.62028 16.5594 4.36328"
        stroke="#444444"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M18.5957 14.7305C20.1467 14.9615 21.2297 15.5055 21.2297 16.6255C21.2297 17.3965 20.7197 17.8965 19.8957 18.2095"
        stroke="#444444"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SettingsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8064 7.62358L20.184 6.54349C19.6574 5.62957 18.4905 5.31429 17.5753 5.83869V5.83869C17.1397 6.09531 16.6198 6.16812 16.1305 6.04106C15.6411 5.91399 15.2224 5.59749 14.9666 5.16134C14.8021 4.88412 14.7137 4.56836 14.7103 4.24601V4.24601C14.7251 3.72919 14.5302 3.22837 14.1698 2.85764C13.8094 2.48691 13.3143 2.27783 12.7973 2.27805H11.5433C11.0367 2.27804 10.5511 2.47988 10.1938 2.83891C9.83644 3.19795 9.63693 3.68456 9.63937 4.19109V4.19109C9.62435 5.23689 8.77224 6.07678 7.72632 6.07667C7.40397 6.07332 7.08821 5.98491 6.81099 5.82038V5.82038C5.89582 5.29598 4.72887 5.61126 4.20229 6.52519L3.5341 7.62358C3.00817 8.53636 3.31916 9.70258 4.22975 10.2323V10.2323C4.82166 10.574 5.18629 11.2056 5.18629 11.889C5.18629 12.5725 4.82166 13.204 4.22975 13.5458V13.5458C3.32031 14.0719 3.00898 15.2353 3.5341 16.1453V16.1453L4.16568 17.2346C4.4124 17.6798 4.82636 18.0083 5.31595 18.1474C5.80554 18.2866 6.3304 18.2249 6.77438 17.976V17.976C7.21084 17.7213 7.73094 17.6515 8.2191 17.7822C8.70725 17.9128 9.12299 18.233 9.37392 18.6716C9.53845 18.9489 9.62686 19.2646 9.63021 19.587V19.587C9.63021 20.6435 10.4867 21.5 11.5433 21.5H12.7973C13.8502 21.5 14.7053 20.6491 14.7103 19.5961V19.5961C14.7079 19.088 14.9086 18.6 15.2679 18.2407C15.6272 17.8814 16.1152 17.6806 16.6233 17.6831C16.9449 17.6917 17.2594 17.7797 17.5387 17.9394V17.9394C18.4515 18.4653 19.6177 18.1543 20.1474 17.2437V17.2437L20.8064 16.1453C21.0615 15.7075 21.1315 15.186 21.001 14.6963C20.8704 14.2067 20.55 13.7893 20.1108 13.5366V13.5366C19.6715 13.2839 19.3511 12.8665 19.2206 12.3769C19.09 11.8873 19.16 11.3658 19.4151 10.9279C19.581 10.6383 19.8211 10.3982 20.1108 10.2323V10.2323C21.0159 9.70286 21.3262 8.54346 20.8064 7.63274V7.63274V7.62358Z"
        stroke="#444444"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        opacity="0.4"
        cx="12.1747"
        cy="11.889"
        r="2.63616"
        stroke="#444444"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PricingIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_4812_656" fill="white">
        <path d="M10 0C4.48625 0 0 4.48625 0 10C0 15.5138 4.48625 20 10 20C15.5138 20 20 15.5138 20 10C20 4.48625 15.5138 0 10 0ZM10 18.75C5.175 18.75 1.25 14.825 1.25 10C1.25 5.175 5.175 1.25 10 1.25C14.825 1.25 18.75 5.175 18.75 10C18.75 14.825 14.825 18.75 10 18.75Z" />
      </mask>
      <path
        d="M10 -1.5C3.65782 -1.5 -1.5 3.65782 -1.5 10H1.5C1.5 5.31468 5.31468 1.5 10 1.5V-1.5ZM-1.5 10C-1.5 16.3422 3.65782 21.5 10 21.5V18.5C5.31468 18.5 1.5 14.6853 1.5 10H-1.5ZM10 21.5C16.3422 21.5 21.5 16.3422 21.5 10H18.5C18.5 14.6853 14.6853 18.5 10 18.5V21.5ZM21.5 10C21.5 3.65782 16.3422 -1.5 10 -1.5V1.5C14.6853 1.5 18.5 5.31468 18.5 10H21.5ZM10 17.25C6.00343 17.25 2.75 13.9966 2.75 10H-0.25C-0.25 15.6534 4.34657 20.25 10 20.25V17.25ZM2.75 10C2.75 6.00343 6.00343 2.75 10 2.75V-0.25C4.34657 -0.25 -0.25 4.34657 -0.25 10H2.75ZM10 2.75C13.9966 2.75 17.25 6.00343 17.25 10H20.25C20.25 4.34657 15.6534 -0.25 10 -0.25V2.75ZM17.25 10C17.25 13.9966 13.9966 17.25 10 17.25V20.25C15.6534 20.25 20.25 15.6534 20.25 10H17.25Z"
        fill="#444444"
        mask="url(#path-1-inside-1_4812_656)"
      />
      <path
        opacity="0.5"
        d="M10 9.375C8.98375 9.375 8.125 8.8025 8.125 8.125C8.125 7.4475 8.98375 6.875 10 6.875C10.575 6.875 11.1075 7.055 11.46 7.3675C11.5213 7.42213 11.5928 7.46412 11.6704 7.49105C11.748 7.51799 11.8302 7.52934 11.9121 7.52446C11.9941 7.51958 12.0744 7.49857 12.1482 7.46263C12.2221 7.42668 12.2881 7.37652 12.3425 7.315C12.5725 7.0575 12.5487 6.6625 12.29 6.4325C11.8525 6.04375 11.265 5.78125 10.625 5.6775V5C10.625 4.655 10.345 4.375 10 4.375C9.655 4.375 9.375 4.655 9.375 5V5.675C7.95 5.9075 6.875 6.9175 6.875 8.125C6.875 9.50375 8.2775 10.625 10 10.625C11.0163 10.625 11.875 11.1975 11.875 11.875C11.875 12.5525 11.0163 13.125 10 13.125C9.425 13.125 8.8925 12.945 8.54 12.6325C8.2825 12.4025 7.8875 12.425 7.6575 12.685C7.4275 12.9425 7.45125 13.3375 7.71 13.5675C8.1475 13.9575 8.735 14.2188 9.375 14.3237V15C9.375 15.345 9.655 15.625 10 15.625C10.345 15.625 10.625 15.345 10.625 15V14.325C12.05 14.0925 13.125 13.0825 13.125 11.875C13.125 10.4963 11.7225 9.375 10 9.375Z"
        fill="#444444"
      />
    </svg>
  );
};

const LockSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="21.6667" height="22" rx="10.8333" fill="#FB5F66" />
      <path
        d="M14.9167 8.914V8.08333C14.9167 7.00037 14.4865 5.96175 13.7207 5.19598C12.9549 4.43021 11.9163 4 10.8333 4C9.75037 4 8.71175 4.43021 7.94598 5.19598C7.18021 5.96175 6.75 7.00037 6.75 8.08333V8.914C6.23046 9.14074 5.78825 9.51397 5.47746 9.98804C5.16666 10.4621 5.00075 11.0165 5 11.5833V15.0833C5.00093 15.8566 5.30851 16.5979 5.8553 17.1447C6.40208 17.6915 7.1434 17.9991 7.91667 18H13.75C14.5233 17.9991 15.2646 17.6915 15.8114 17.1447C16.3582 16.5979 16.6657 15.8566 16.6667 15.0833V11.5833C16.6659 11.0165 16.5 10.4621 16.1892 9.98804C15.8784 9.51397 15.4362 9.14074 14.9167 8.914ZM7.91667 8.08333C7.91667 7.30979 8.22396 6.56792 8.77094 6.02094C9.31792 5.47396 10.0598 5.16667 10.8333 5.16667C11.6069 5.16667 12.3487 5.47396 12.8957 6.02094C13.4427 6.56792 13.75 7.30979 13.75 8.08333V8.66667H7.91667V8.08333ZM15.5 15.0833C15.5 15.5475 15.3156 15.9926 14.9874 16.3208C14.6592 16.649 14.2141 16.8333 13.75 16.8333H7.91667C7.45254 16.8333 7.00742 16.649 6.67923 16.3208C6.35104 15.9926 6.16667 15.5475 6.16667 15.0833V11.5833C6.16667 11.1192 6.35104 10.6741 6.67923 10.3459C7.00742 10.0177 7.45254 9.83333 7.91667 9.83333H13.75C14.2141 9.83333 14.6592 10.0177 14.9874 10.3459C15.3156 10.6741 15.5 11.1192 15.5 11.5833V15.0833Z"
        fill="white"
      />
      <path
        d="M10.8353 11C10.548 11 10.2724 11.0966 10.0693 11.2685C9.86609 11.4404 9.75195 11.6736 9.75195 11.9167V13.75C9.75195 13.9931 9.86609 14.2263 10.0693 14.3982C10.2724 14.5701 10.548 14.6667 10.8353 14.6667C11.1226 14.6667 11.3982 14.5701 11.6013 14.3982C11.8045 14.2263 11.9186 13.9931 11.9186 13.75V11.9167C11.9186 11.6736 11.8045 11.4404 11.6013 11.2685C11.3982 11.0966 11.1226 11 10.8353 11Z"
        fill="white"
      />
    </svg>
  );
};
