import environment from "../constants/Environment";

export const OmniLogoWithText = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4415_4812)">
      <path
        d="M33.3978 16.4477C35.3083 15.6099 37.5778 16.4794 38.0193 18.5184C38.6814 21.5765 38.5776 24.7718 37.6889 27.813C36.4444 32.0715 33.7405 35.756 30.0513 38.2204C26.3622 40.6848 21.9232 41.7717 17.5127 41.2907C13.1023 40.8097 9.0019 38.7914 5.93072 35.5897C2.85954 32.388 1.01345 28.2073 0.716267 23.7805C0.419086 19.3539 1.68975 14.964 4.30541 11.3805C6.92112 7.79696 10.7149 5.24863 15.0215 4.18245C18.097 3.421 21.2939 3.45013 24.3218 4.23893C26.3406 4.76482 27.115 7.06839 26.1985 8.94248C25.2819 10.8165 23.0049 11.5199 20.9341 11.2671C19.5749 11.1011 18.1865 11.1815 16.837 11.5157C14.2531 12.1554 11.9768 13.6844 10.4075 15.8345C8.83801 17.9845 8.07567 20.6186 8.25394 23.2746C8.43222 25.9305 9.53986 28.439 11.3826 30.36C13.2253 32.281 15.6855 33.492 18.3318 33.7807C20.9781 34.0693 23.6415 33.417 25.855 31.9385C28.0685 30.4598 29.6908 28.2491 30.4375 25.6941C30.8274 24.3596 30.9656 22.9758 30.8562 21.6108C30.6897 19.5313 31.4873 17.2855 33.3978 16.4477Z"
        fill="#FB5F66"
      />
      <path
        d="M40.3186 10.423C39.6585 10.9398 37.6981 10.1971 35.5784 9.22143C33.8886 9.86707 32.7959 9.50095 35.0816 11.9622C36.7473 13.7556 31.917 13.5407 29.1286 16.3292C25.5389 20.0512 27.1064 28.4063 22.6958 21.4388C21.034 18.8134 23.3945 26.8285 22.2747 27.1545C20.7898 27.4046 20.9717 25.5945 20.4394 21.3559C20.1857 19.3353 19.8853 21.1684 19.5246 23.3868C21.0679 27.0773 20.5586 24.6625 19.4579 25.9998L19.1372 25.6371C18.8694 27.0202 18.5729 28.0554 18.2438 27.7263C17.4837 26.9661 16.7156 23.0331 15.6838 21.7323L15.5449 21.5753C15.5917 21.6222 15.638 21.6747 15.6838 21.7323L19.1372 25.6371C19.2736 24.9325 19.4026 24.1376 19.5246 23.3868C19.0544 22.2623 18.3935 20.5708 17.5008 18.0337C16.5506 14.2707 22.9872 16.3841 25.6064 14.2941C28.2257 12.2041 29.2246 9.48522 28.8142 6.72019C28.8659 5.77332 32.4012 7.75911 35.5784 9.22143C36.1192 9.01473 36.7211 8.70447 37.2931 8.16455C37.7005 7.77993 38.0229 7.36583 38.2314 6.92188L34.6388 0.895508C38.2465 3.30488 38.9887 5.3098 38.2314 6.92188L40.3186 10.423Z"
        fill="#444444"
      />
      <path
        d="M39.6224 2.80951C36.9999 0.187049 32.7481 0.187051 30.1256 2.80951C27.5032 5.43198 27.5032 9.68383 30.1256 12.3063C32.7481 14.9288 36.9999 14.9288 39.6224 12.3063C42.2449 9.68383 42.2449 5.43198 39.6224 2.80951Z"
        fill="#444444"
      />
      <path
        d="M25.4959 16.936C22.349 13.789 17.2467 13.789 14.0998 16.936C10.9528 20.0829 10.9528 25.1851 14.0998 28.3321C17.2467 31.479 22.349 31.479 25.4959 28.3321C28.6429 25.1851 28.6429 20.0829 25.4959 16.936Z"
        fill="#444444"
      />
    </g>
    <defs>
      <clipPath id="clip0_4415_4812">
        <rect width="42" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
