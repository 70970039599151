import "../styles/svg.css";

const Error404_1 = () => (
  <svg
    id="eMEeDY1rA891"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 421 300"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    style={{ height: "300px" }}
  >
    <defs>
      <linearGradient
        id="eMEeDY1rA895-stroke"
        x1="0"
        y1="0.500002"
        x2="1.000036"
        y2="0.500002"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="translate(0 0)"
      >
        <stop id="eMEeDY1rA895-stroke-0" offset="0%" stopColor="#face00" />
        <stop id="eMEeDY1rA895-stroke-1" offset="100%" stopColor="#ffbf00" />
      </linearGradient>
    </defs>
    <path
      d="M212.902,300c114.624,0,207.545-6.867,207.545-15.338c0-8.472-92.921-15.339-207.545-15.339-114.6237,0-207.54458,6.867-207.54458,15.339C5.35742,293.133,98.2783,300,212.902,300Z"
      opacity="0.45"
      fill="#e6e6e6"
    />
    <g id="eMEeDY1rA893_to" transform="translate(98.950951,188.033303)">
      <g id="eMEeDY1rA893_tr" transform="rotate(0)">
        <g transform="translate(-96.535603,-47.033302)">
          <path
            d="M96.5355,71.7038c13.6245,0,24.6705-11.0453,24.6705-24.6703s-11.046-24.6702-24.6705-24.6702c-13.625,0-24.6703,11.0452-24.6703,24.6702s11.0453,24.6703,24.6703,24.6703Z"
            fill="#ffd200"
          />
          <path
            d="M96.5392,94.0666c25.9758,0,47.0328-21.0575,47.0328-47.0333s-21.057-47.0333-47.0328-47.0333-47.0333,21.0575-47.0333,47.0333s21.0575,47.0333,47.0333,47.0333Z"
            transform="translate(-.00335 0)"
            opacity="0.34"
            fill="#ffd200"
            stroke="url(#eMEeDY1rA895-stroke)"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
    <path
      d="M24.15963,158.684q8.152731-14.594589,25.95367-25.2016c17.800939-10.607011,29.472193-7.080251,42.7927-11.814491s21.300819-4.918136,32.746038-9.176023q11.445219-4.257887,26.401242-9.689817l1.560123,122.358319-127.087704,6.766999L24.15963,158.684Z"
      transform="translate(.000003 0.000002)"
      fill="#ededed"
      stroke="#fff"
      strokeWidth="0.842"
    />
    <path
      d="M71.4468,125.749c-35.652,6.328-60.8507,37.379-56.7132,71.445c2.2041,18.046,11.1364,34.067,34.1181,38.669C109.818,248.12,339.958,294.264,385.174,214.312c35.252-62.308,14.243-105.448-18.496-132.4516-32.739-27.0032-100.537-21.3963-156.645-2.913C168.31,92.7261,137.286,114.045,71.4468,125.749v0Z"
      transform="translate(1.212527 0.013864)"
      fill="#f6f6f6"
    />
    <g>
      <path
        d="M200.133,283.953c36.426,0,65.955-29.529,65.955-65.955s-29.529-65.955-65.955-65.955-65.955,29.529-65.955,65.955s29.529,65.955,65.955,65.955Z"
        fill="#24285b"
      />
      <path
        d="M120.502,233.736c4.37,0,5.942,1.572,5.942,5.929v7.515c0,4.369-1.289,5.942-5.942,5.942h-12.051v22.878c0,4.357-1.573,5.929-5.942,5.929h-8.559c-4.3566,0-5.9291-1.572-5.9291-5.929v-22.878h-57.6283c-4.3567,0-5.9292-1.573-5.9292-5.942v-6.29c-.1171-2.547.6076-5.061,2.0623-7.154l58.0022-71.961c.9716-1.305,2.2358-2.364,3.6908-3.091c1.4551-.728,3.0605-1.104,4.6873-1.098h9.667c4.37,0,5.942,1.224,5.942,5.942v70.208h11.987ZM88.0209,186.922L50.1133,233.736h37.9076v-46.814Z"
        fill="#fb5f66"
      />
      <path
        d="M369.922,233.736c4.369,0,5.942,1.572,5.942,5.929v7.515c0,4.369-1.225,5.942-5.942,5.942h-12.052v22.878c0,4.357-1.572,5.929-5.942,5.929h-8.558c-4.357,0-5.93-1.572-5.93-5.929v-22.878h-57.641c-4.356,0-5.929-1.573-5.929-5.942v-6.29c-.11-2.55.624-5.064,2.088-7.154l58.002-71.961c.972-1.305,2.236-2.364,3.691-3.091c1.455-.728,3.061-1.104,4.687-1.098h9.603c4.37,0,5.942,1.224,5.942,5.942v70.208h12.039ZM337.44,186.922l-37.907,46.814h37.907v-46.814Z"
        fill="#fb5f66"
      />
    </g>
    <path
      d="M200.138,259.514c22.929,0,41.516-18.587,41.516-41.516s-18.587-41.517-41.516-41.517-41.517,18.588-41.517,41.517s18.588,41.516,41.517,41.516Z"
      fill="#fff"
    />
    <g id="eMEeDY1rA8913" transform="translate(.247322 0)" opacity="0">
      <g id="eMEeDY1rA8914_tr" transform="translate(339.574177,137.246056) rotate(20)">
        <path
          d="M344.06,143.536c0,0-10.968-2.99-13.353-13.211c0,0,17.001-3.429,17.491,14.113l-4.138-.902Z"
          transform="translate(-339.452499,-137.246056)"
          opacity="0.58"
          fill="#fb5f66"
        />
      </g>
      <g id="eMEeDY1rA8915_tr" transform="translate(346.671689,130.737003) rotate(10)">
        <path
          d="M345.414,142.453c0,0-7.669-12.129-.928-23.458c0,0,12.889,8.21,7.192,23.484l-6.264-.026Z"
          transform="translate(-347.422226,-130.737003)"
          opacity="0.73"
          fill="#fb5f66"
        />
      </g>
      <g id="eMEeDY1rA8916_tr" transform="translate(354.152965,134.923517) rotate(-20)">
        <path
          d="M347.379,142.466c0,0,4.047-12.812,16.305-15.235c0,0,2.294,8.314-7.94,15.261l-8.365-.026Z"
          transform="translate(-355.630875,-134.8615)"
          fill="#fb5f66"
        />
      </g>
    </g>
    <path d="M339.422,142.17l2.217,15.222l14.011.065l2.062-15.21-18.29-.077Z" fill="#24285b" />
    <path
      d="M390.643,84.3353c0-2.3108-.918-4.5271-2.552-6.1611-1.634-1.6341-3.851-2.5521-6.161-2.5521-.471-.0003-.941.0385-1.405.116-1.001-1.8658-2.488-3.4261-4.303-4.5154s-3.892-1.6668-6.009-1.6715h-.425c.511-2.0553.547-4.2001.106-6.2717-.441-2.0715-1.348-4.0154-2.653-5.6841-1.304-1.6686-2.971-3.0183-4.875-3.9465s-3.994-1.4106-6.112-1.4106-4.208.4824-6.112,1.4106-3.571,2.2779-4.876,3.9465c-1.304,1.6687-2.211,3.6126-2.652,5.6841-.442,2.0716-.405,4.2164.106,6.2717h-.425c-1.576-.0522-3.146.2131-4.617.78-1.471.567-2.813,1.424-3.946,2.5201s-2.034,2.4089-2.649,3.8604-.933,3.0117-.933,4.5882.318,3.1368.933,4.5882c.615,1.4515,1.516,2.7643,2.649,3.8604s2.475,1.9531,3.946,2.5201c1.471.5669,3.041.8322,4.617.78h40.769v-.0774c2.095-.2778,4.017-1.3063,5.411-2.8945s2.164-3.6282,2.168-5.7414v0Z"
      transform="translate(3.12371-32.249484)"
      fill="#e6e6e6"
    />
    <path
      d="M51.9179,170.578c0-.982-.1934-1.954-.5691-2.861s-.9264-1.731-1.6206-2.425-1.5183-1.245-2.4253-1.621-1.8791-.569-2.8609-.569c-.4014.008-.8019.042-1.1987.103-.8627-1.603-2.1443-2.943-3.7082-3.876s-3.3515-1.424-5.1726-1.421h-.3609c.4435-1.764.4788-3.607.1031-5.386-.3756-1.78-1.1522-3.451-2.2707-4.885-1.1186-1.435-2.5496-2.595-4.1841-3.394-1.6346-.798-3.4297-1.213-5.2487-1.213s-3.6141.415-5.2487,1.213c-1.6345.799-3.0655,1.959-4.184,3.394-1.1186,1.434-1.8952,3.105-2.2708,4.885-.3757,1.779-.3404,3.622.1031,5.386h-.3609c-1.35316-.049-2.70217.176-3.96654.66-1.26437.485-2.41819,1.219-3.39257,2.159s-1.74936,2.067-2.278676,3.313C0.2728,165.287,0,166.627,0,167.981s.2728,2.694.802114,3.94s1.304296,2.373,2.278676,3.313s2.1282,1.675,3.39257,2.159s2.61338.709,3.96654.661h34.9688v-.065c1.7987-.237,3.45-1.119,4.6472-2.482s1.8588-3.115,1.862-4.929v0Z"
      transform="translate(17-67)"
      fill="#e6e6e6"
    />
    <path d="" fill="none" stroke="#3f5787" strokeWidth="0.842" />
    <g transform="translate(0 0.000006)" clipPath="url(#eMEeDY1rA8942)">
      <g id="eMEeDY1rA8922_ts" transform="translate(212.90221,259.505528) scale(0,0)">
        <g transform="translate(-212.90221,-258.741139)">
          <g id="eMEeDY1rA8923_tr" transform="translate(201.5015,139.47451) rotate(0)">
            <g transform="translate(-201.501489,-139.47451)">
              <path
                d="M208.115,131.394c0,0,1.804,8.701,1.018,14.733-.073.586-.262,1.153-.555,1.666-.294.512-.687.962-1.156,1.322s-1.005.623-1.576.773c-.571.151-1.167.187-1.753.106-3.029-.439-6.999-1.908-8.532-6.445l-3.558-7.411c-.555-1.558-.614-3.25-.168-4.842.445-1.593,1.373-3.008,2.656-4.052c4.563-4.202,12.786-1.147,13.624,4.15v0Z"
                fill="#f4a28c"
              />
              <path
                d="M205.959,132.013c-2.714.435-5.469.552-8.21.348.928,1.108,1.513,2.463,1.682,3.898.17,1.436-.083,2.89-.729,4.183-.602,1.266-1.626,2.283-2.896,2.875-1.27.593-2.707.725-4.064.374l-1.199-11.369c-.23-1.62-.017-3.272.619-4.779.636-1.508,1.669-2.814,2.991-3.779c1.136-.849,2.329-1.62,3.57-2.308c3.106-1.701,8.146-.09,10.814-2.642.271-.253.603-.431.963-.518s.736-.08,1.093.021c.356.101.681.293.941.556.261.263.45.589.548.946.928,3.39.953,8.894-3.493,11.33-.822.435-1.711.727-2.63.864v0Z"
                fill="#24285b"
              />
              <path
                d="M208.961,136.937l2.875,3.106c.162.178.278.394.335.628s.054.479-.009.712-.184.446-.352.619c-.167.174-.376.302-.606.374l-3.3,1.031l1.057-6.47Z"
                fill="#f4a28c"
              />
              <path d="M199.62,140.068c0,0-.464-3.402-2.99-2.835-2.527.567-1.882,5.478,1.649,5.516l1.341-2.681Z" fill="#f4a28c" />
            </g>
          </g>
          <path d="M192.644,137.439l-1.16,29.143l16.099-.516-5.62-20.997-9.319-7.63Z" fill="#f4a28c" />
          <path
            d="M203.396,149.62c-2.049-.268-3.975-1.128-5.542-2.475c0,0,.85,5.272,7.295,9.809l-1.753-7.334Z"
            opacity="0.31"
            fill="#ce8172"
          />
          <path
            d="M191.511,166.582l16.099-.515c0,0,25.288-4.293,34.066,16.33s-2.475,49.766-2.475,49.766-9.151,30.767-47.69,27.364c0,0-32.056-1.856-35.678-45.796-.164-1.916-.457-3.819-.877-5.697-1.469-6.87-2.32-27.712,36.555-41.452v0Z"
            fill="#fb5f66"
          />
          <path
            d="M169.947,193.959c0,0,8.585.941,20.507,20.907c11.923,19.965,35.33,12.644,48.529-2.127l-24.49,32.404-27.429-2.204-14.913-39.7-2.204-9.28Z"
            opacity="0.08"
          />
          <g id="eMEeDY1rA8932" opacity="0">
            <path d="M259.236,148.377l4.715-.629l1.833,13.721-4.714.63-1.834-13.722Z" fill="#ffd200" />
            <path d="M259.236,148.377l4.715-.629l1.833,13.721-4.714.63-1.834-13.722Z" opacity="0.08" />
            <path d="M259.355,158.451l7.244-.968l2.354,17.618-7.244.968-2.354-17.618Z" fill="#24285b" />
            <path
              d="M257.246,119.356c-3.057.409-5.924,1.716-8.239,3.755-2.314,2.039-3.972,4.718-4.764,7.699-.791,2.981-.682,6.13.316,9.049s2.839,5.476,5.29,7.348c2.451,1.873,5.402,2.976,8.48,3.172c3.078.195,6.145-.527,8.813-2.074c2.668-1.548,4.818-3.852,6.176-6.621c1.359-2.769,1.866-5.879,1.458-8.936-.272-2.031-.941-3.988-1.968-5.76-1.028-1.772-2.395-3.324-4.023-4.567-1.628-1.244-3.485-2.155-5.465-2.681-1.98-.525-4.044-.656-6.074-.384Zm3.532,26.372c-2.161.292-4.36-.064-6.319-1.022s-3.59-2.476-4.685-4.361c-1.096-1.886-1.608-4.054-1.471-6.23.136-2.176.916-4.263,2.239-5.996c1.324-1.734,3.131-3.035,5.195-3.741c2.063-.705,4.29-.782,6.397-.222c2.107.561,4.001,1.734,5.441,3.371c1.441,1.637,2.363,3.665,2.651,5.827.382,2.885-.391,5.803-2.151,8.121s-4.364,3.846-7.246,4.253h-.051Z"
              fill="#ffd200"
            />
            <path
              d="M259.394,145.933c6.115,0,11.072-4.957,11.072-11.071c0-6.115-4.957-11.072-11.072-11.072s-11.072,4.957-11.072,11.072c0,6.114,4.957,11.071,11.072,11.071Z"
              fill="#fff"
            />
          </g>
          <g transform="translate(.000001 0)">
            <path
              d="M155.417,196.124c.393-1.512,1.221-2.876,2.383-3.922c1.162-1.045,2.606-1.725,4.152-1.956c1.545-.231,3.125-.002,4.541.658c1.417.66,2.608,1.723,3.425,3.055c6.152,9.959,11.331,20.486,15.467,31.437c9.216,24.567,54.406,8.365,71.085-48.915l9.512,5.916c0,0-13.121,74.398-65.942,77.13c0,0-32.971,6.677-42.986-33.783c0,0-2.577-7.617-2.732-11.923l-.696-4.846c-.107-4.304.467-8.597,1.701-12.722l.09-.129Z"
              fill="#fb5f66"
            />
            <path
              d="M155.417,196.124c.393-1.512,1.221-2.876,2.383-3.922c1.162-1.045,2.606-1.725,4.152-1.956c1.545-.231,3.125-.002,4.541.658c1.417.66,2.608,1.723,3.425,3.055c6.152,9.959,11.331,20.486,15.467,31.437c9.216,24.567,54.406,8.365,71.085-48.915l9.512,5.916c0,0-13.121,74.398-65.942,77.13c0,0-32.971,6.677-42.986-33.783c0,0-2.577-7.617-2.732-11.923l-.696-4.846c-.107-4.304.467-8.597,1.701-12.722l.09-.129Z"
              opacity="0.39"
              fill="#fff"
            />
            <path d="M258.885,177.925c0,0,.966-12.258,6.019-12.232s16.924,9.1-1.405,15.093l-4.614-2.861Z" fill="#f4a28c" />
          </g>
        </g>
      </g>
      <clipPath id="eMEeDY1rA8942">
        <path
          d="M134.177998,119.131132Q153.986,226.208,161.952,239.665c7.966,13.457,21.754,19.626388,23.433,19.626388s27.027208,2.045221,37.189009-6.16939s12.671122-9.024721,22.278056-21.194611c2.143608-2.715483,16.250723-31.359888,22.651801-50.383458c12.295132-8.391326,3.888969-13.684428,2.722132-15.937831.055409-23.655792,9.974512-18.435005,2.582-46.878993l-138.63.404027Z"
          transform="translate(.000002 0.000004)"
          fill="none"
          stroke="#3f5787"
          strokeWidth="0.842"
        />
      </clipPath>
    </g>
    <path d="" fill="none" stroke="#3f5787" strokeWidth="0.842" />
  </svg>
);

export default Error404_1;
