import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosGet, axiosPost } from "../../services/axiosService";
import $ from "jquery";
import { userSlice } from "../../redux/User";
import { State } from "../../redux/Types";
import { setCookie, deleteCookies } from "../../services/cookieService";
import environment from "../../constants/Environment";
import useGetAllCompanies from "@/hooks/useGetAllCompanies";
import { useQueryClient } from "@tanstack/react-query";

const UserSection = (props) => {
  const { setRoute } = props;
  const userName = useSelector((state: State) => state.user.userName);
  // const [companies, setCompanies] = useState<any>();
  let companies: any[] = [];
  const companyName: any = useSelector(
    (state: State) => state.user.companyDetails.compName,
  );
  const [companyId, setCompanyId] = useState<any>(
    useSelector((state: State) => state.user.companyDetails._id),
  );
  const [loader, setLoader] = useState<any>(false);
  const environmentData = environment();
  const dispatch = useDispatch();
  const router = useNavigate();
  const role = useSelector((state: State) => state.user.role);
  const queryClient = useQueryClient();
  const allCompanies = useGetAllCompanies();
  if (allCompanies.isSuccess) {
    companies = allCompanies.data;
  }

  useEffect(() => {
    if (loader) {
      queryClient.invalidateQueries({ queryKey: ["getAllCompanies"] });
    }
  }, [loader]);

  // useEffect(() => {
  //   axiosGet(`/users/getAllCompanies`).then((response) => {
  //     setCompanies(response.data.data);
  //     const company = response?.data?.data.find(
  //       (comp) => comp?.companyId?._id === companyId
  //     );
  //     dispatch(userSlice.actions.setRole(company?.role));
  //     dispatch(
  //       userSlice.actions.setCompanyLogo({
  //         logo: company?.companyId?.logo,
  //       })
  //     );

  //     dispatch(
  //       userSlice.actions.setCompanyName({
  //         compName: company?.companyId?.compName,
  //       })
  //     );

  //     // if (response.data.data.length) {
  //     //   const company = response?.data?.data.find((comp) => comp?.companyId?._id === companyId);
  //     //   dispatch(
  //     //     userSlice.actions.setCompanyName({
  //     //       compName: company?.companyId?.compName,
  //     //     })
  //     //   );
  //     //   // setCompanyName(company?.companyId?.compName ?? "Not Found");
  //     // } else {
  //     //   console.log("No Companies Found");
  //     // }
  //   });
  // }, [loader, companyLogo, companyName]);

  const switchCompany = (companyId) => {
    setLoader(true);
    dispatch(userSlice.actions.setSwitchLoad(true));
    const body = {
      companyId: companyId,
    };
    axiosPost(`/users/switchCompany`, body)
      .then((response) => {
        dispatch(
          userSlice.actions.setCompanyDetails({
            compName: response.data.data.compName,
            count: response.data.data.count,
            createdAt: response.data.data.createdAt,
            subDomain: response.data.data.subDomain,
            updatedAt: response.data.data.updatedAt,
            userId: response.data.data.userId,
            id: response.data.data._id,
          }),
        );

        // resetting the bot, workspace and chat data
        const queryCache = queryClient.getQueryCache();
        queryCache.clear();
        dispatch(userSlice.actions.setSelectedBotId(""));
        dispatch(userSlice.actions.setSelectedBot(""));
        dispatch(userSlice.actions.setSelectedChat(""));
        dispatch(userSlice.actions.setSelectedSenderId(""));

        setCompanyId(response.data.data._id);
        dispatch(userSlice.actions.setSwitchLoad(false));
        setCookie(
          "token",
          response.data.token,
          1,
          environmentData?.PUBLIC_DOMAIN,
          "/",
        );
        setCookie(
          "token",
          response.data.token,
          1,
          environmentData?.PAGE_DOMAIN,
          "/",
        );
        setLoader(false);
        const switchCompRole = companies.find(
          (item) => item.companyId._id == response.data.data._id,
        );
        dispatch(userSlice.actions.setRole(switchCompRole?.role));
        dispatch(
          userSlice.actions.setCompanyName({
            compName: switchCompRole?.companyId?.compName,
          }),
        );

        const inBuilder = window.location.pathname.includes("/builder");
        if (inBuilder) router("/dashboard");
      })
      .catch((err) => {
        console.log(err);
        dispatch(userSlice.actions.setSwitchLoad(false));
        setLoader(false);
      });
  };

  const handleLogout = (e) => {
    if ($(".user_details > .user_profile_dropdown").hasClass("none")) {
      $(".user_details > .user_profile_dropdown").removeClass("none");
      $("#user_svg").css("transform", "rotate(-180deg)");
    } else {
      $(".user_details > .user_profile_dropdown").addClass("none");
      $("#user_svg").css("transform", "rotate(0deg)");
    }
  };
  return (
    <Fragment>
      <div className="user_section fx al-cnt">
        <div
          className="user_details"
          onClick={(e) => {
            handleLogout(e);
          }}
        >
          <div className="user_info fx al-cnt">
            <div className="user_id flex-cnt">
              {userName && userName.slice(0, 1).toUpperCase()}
            </div>
            <div>
              <div className="user_name">
                {" "}
                {userName && userName.length > 10
                  ? userName.slice(0, 10) + "..."
                  : userName}
              </div>
              <div className="cmpny_name">
                {companyName && companyName.length > 15
                  ? companyName.slice(0, 15) + "..."
                  : companyName}
              </div>
            </div>
          </div>

          <div className="user_profile_dropdown none">
            <div className="logout-btn" id="logout-user">
              <ul>
                <li className="user-acc-list-outer">
                  <ul className="user-acc-list">
                    <p className="companies-heading">COMPANIES</p>
                    {companies &&
                      companies.map((value, index) => (
                        <li
                          key={index}
                          className={
                            ` gap-5 ${value.companyId._id === companyId
                              ? "acc-list-active"
                              : ""}`
                          }
                          onClick={() => switchCompany(value.companyId._id)}
                        >
                          <div className="cc-name">
                            {value.companyId.logo ? (
                              <img
                                className="companyLogo"
                                src={value.companyId.logo}
                              />
                            ) : (
                              <>
                                <span>
                                  {value.companyId.compName.slice(0, 1)}
                                </span>
                              </>
                            )}
                          </div>
                          <span className="cc-desc">
                            <p>
                              {value.companyId.compName.length > 16
                                ? value.companyId.compName.slice(0, 16) + "..."
                                : value.companyId.compName}
                            </p>
                          </span>
                        </li>
                      ))}
                  </ul>
                </li>
                <li
                  className="user-logout"
                  onClick={() => {
                    router("/logout");
                  }}
                >
                  <svg
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_350_20944)">
                      <mask
                        id="mask0_350_20944"
                        style={{
                          maskType: "luminance",
                        }}
                        maskUnits="userSpaceOnUse"
                        x={0}
                        y={0}
                        width={18}
                        height={18}
                      >
                        <path d="M18 0H0V18H18V0Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_350_20944)">
                        <path
                          d="M16.9091 8.40334L13.7271 5.22138C13.6488 5.14303 13.5558 5.08087 13.4534 5.03847C13.351 4.99606 13.2413 4.97424 13.1305 4.97424C13.0197 4.97424 12.91 4.99606 12.8076 5.03847C12.7052 5.08087 12.6122 5.14303 12.5339 5.22138C12.3756 5.37962 12.2867 5.59423 12.2867 5.818C12.2867 6.04178 12.3756 6.25639 12.5339 6.41462L14.2755 8.15623H5.0625C4.83872 8.15623 4.62411 8.24512 4.46588 8.40335C4.30764 8.56159 4.21875 8.7762 4.21875 8.99998C4.21875 9.22375 4.30764 9.43836 4.46588 9.5966C4.62411 9.75483 4.83872 9.84373 5.0625 9.84373H14.2755L12.5339 11.5854C12.3756 11.7436 12.2867 11.9582 12.2867 12.182C12.2868 12.4058 12.3757 12.6204 12.5339 12.7786C12.6921 12.9368 12.9067 13.0257 13.1305 13.0257C13.3543 13.0257 13.5689 12.9368 13.7271 12.7786L16.9091 9.59658C17.0674 9.43834 17.1563 9.22373 17.1563 8.99996C17.1563 8.77618 17.0674 8.56157 16.9091 8.40334Z"
                          fill="#444444"
                        />
                        <path
                          d="M10.6875 13.7812C10.4637 13.7812 10.2491 13.8701 10.0909 14.0284C9.93264 14.1866 9.84375 14.4012 9.84375 14.625V15.4688H2.53125V2.53125H9.84375V3.375C9.84375 3.59878 9.93264 3.81339 10.0909 3.97162C10.2491 4.12986 10.4637 4.21875 10.6875 4.21875C10.9113 4.21875 11.1259 4.12986 11.2841 3.97162C11.4424 3.81339 11.5312 3.59878 11.5312 3.375V2.25C11.5312 2.06533 11.4949 1.88247 11.4242 1.71185C11.3535 1.54124 11.25 1.38621 11.1194 1.25563C10.9888 1.12505 10.8338 1.02147 10.6631 0.950794C10.4925 0.880124 10.3097 0.84375 10.125 0.84375H2.25C1.87704 0.84375 1.51935 0.991908 1.25563 1.25563C0.991908 1.51935 0.84375 1.87704 0.84375 2.25V15.75C0.84375 16.123 0.991908 16.4806 1.25563 16.7444C1.51935 17.0081 1.87704 17.1562 2.25 17.1562H10.125C10.3097 17.1562 10.4925 17.1199 10.6631 17.0492C10.8338 16.9785 10.9888 16.875 11.1194 16.7444C11.25 16.6138 11.3535 16.4588 11.4242 16.2881C11.4949 16.1175 11.5312 15.9347 11.5312 15.75V14.625C11.5312 14.4012 11.4424 14.1866 11.2841 14.0284C11.1259 13.8701 10.9113 13.7812 10.6875 13.7812Z"
                          fill="#444444"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_350_20944">
                        <rect width={18} height={18} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="user-logout">Logout</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserSection;
