import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "../redux/Types";
import environment from "../constants/Environment";
import useGetPlanDetails from "@/hooks/useGetPlanDetails";
import { useQueryClient } from "@tanstack/react-query";

const environmentData = environment();

export default function AuthGuard({ children }) {
  const router = useNavigate();
  const isLoggedIn = useSelector((state: State) => state.user.isLoggedIn);
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const planDetails = useGetPlanDetails();

  useEffect(() => {
    if (!isLoggedIn) {
      queryCache.clear();
      router("/login");
    }
  }, [isLoggedIn, queryCache, router]);

  useEffect(() => {
    if (planDetails.isSuccess) {
      if (
        planDetails.data?.plan?.status === "expired" ||
        planDetails.data?.plan?.status === "cancelled"
      ) {
        if (planDetails.data?.plan?.plan_code === environmentData.TRIALPLAN) {
          router("/trial_expired");
        } else {
          router("/plan_expired");
        }
      }
    } else if (planDetails.isError) {
      console.log(planDetails.error);
    }
  }, [planDetails.data]);

  return <>{children}</>;
}
